import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { CreateIntake, CreateIntakeBegin } from '../../../../actions/intakes';

import Validation from '../../../../utils/validation-schema-create-form';

import CombineStyles from '../../../../utils/combine-styles';
import Styles from './dialog-new-form-styles';
import ButtonStyles from '../../../../styles/buttons';
import InputStyles from '../../../../styles/inputs';

const NewFormDialog = ({
    classes,
    open,
    handleClose,
}) => {
    // no design concept of API error
    // const createFormError = useSelector(state => state.intakes.createIntake.error);
    // const createFormMsg = useSelector(state => state.intakes.createIntake.errorMsg);
    // const [errorAPI, setErrorAPI] = useState(null);
    // const [errorMsgAPI, setErrorMsgAPI] = useState(null);

    const defaultAnswerSet = useSelector(state => state.intakes.defaultAnswerSet);
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);

    const [values, setValues] = useState({
        clientFirstName: '',
        clientLastName: '',
    });
    const [errors, setErrors] = useState({});
    const [validationActive, setValidationActive] = useState(false);

    const closeDialog = () => {
        handleClose();
        // component isn't unmounted, so let's manually reset state
        // this is only triggered when user indicts a close func, not a history push
        setStep(1);
        setValues({ clientLastName: '', clientFirstName: '' });
        setValidationActive(false);
        setErrors({});
        // TODO: check to see if this is really needed
        // TODO: consider making cleanup action to clarify in redux tools
        dispatch(CreateIntakeBegin());
    };

    const validate = () => {
        setValidationActive(true);
        const validationError = Validation.validate(
            {
                clientFirstName: values.clientFirstName,
                clientLastName: values.clientLastName,
            },
            { abortEarly: false },
        ).error;

        if (validationError) {
            const currentErrors = {};
            validationError.details.forEach((detail) => {
                currentErrors[detail.context.key] = detail.type;
            });

            setErrors(currentErrors);
        } else {
            setErrors({});
        }
        return validationError;
    };
    const createNewForm = () => {
        if (!validate()) {
            dispatch(CreateIntake({ ...values, answers: defaultAnswerSet }));
            // if we were error handling, this close should only be triggered on success
            closeDialog();
            // success/redirect is handled in 'actions'
        }
    };

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [
        values.clientFirstName,
        values.clientLastName,
    ]);

    const handleChange = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const step1Content = (
        <>
            <Typography variant="h1" className={classes.dialogTitle}>
                Client Consent Policy
            </Typography>
            <MuiDialogContent className={classes.contentWrapper}>
                <Typography variant="h3" component="h2" className={classes.h3Text}>
                    Please read and agree to the client consent policy
                </Typography>
                <div style={{ padding: 15, backgroundColor: 'white', marginBottom: 30 }}>
                    <div className={classes.policyTextWrapper}>
                        <Typography variant="h3" className={classes.policyText} tabIndex="0">
                            I am an attorney who is seeking to submit client-specific information to myPadilla. By my affirmation below, I acknowledge and agree to the following:
                            <ol>
                                <li>
                                    I have carefully reviewed the&nbsp;
                                    <Link to="/terms" className={classes.tosLink} target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>
                                    &nbsp;I previously agreed to regarding myPadilla, and I affirm that I agree that all such Terms and Conditions shall be fully applicable to any information myPadilla provides in response to this specific client.
                                </li>
                                <li>I expressly reaffirm, without in any way limiting the broader effect of Terms and Conditions, that:</li>
                                <ol type="i">
                                    <li>myPadilla only has a contractual relationship with myself, and there is no contractual relationship of any kind (including but not limited to an attorney-client relationship) between myPadilla and this client;</li>
                                    <li>
                                        the only services myPadilla will provide will be short-term, limited-scope consultation, in order provide information intended to assist me in meeting my obligations under&nbsp;
                                        <i>Padilla;</i>
                                    </li>
                                    <li>I am solely responsible for my conduct and my actions on behalf of my client, I must rely on my own research, investigation, professional judgment, and consultation with my clients in determining strategy and making decisions about this matter, and all decisions I make with regards to this matter are my full and sole responsibility.</li>
                                </ol>
                                <li>I have obtained informed consent from this specific client regarding the Terms and Conditions, including but not limited to informed consent from my client permitting me to share his/her information with myPadilla, and informed consent regarding myPadilla’s data retention practices. I understand that sharing my client’s information with myPadilla without obtaining his/her informed consent could be a violation of Texas Rules of Disciplinary Conduct, including Rules 1.05 and 1.03. I acknowledge that it is my responsibility to comply with all Texas Rules of Disciplinary Conduct, and all other relevant rules of professional conduct, when using myPadilla.</li>
                            </ol>
                        </Typography>
                    </div>
                </div>
            </MuiDialogContent>
            <MuiDialogActions className={classes.actionsWrapper}>
                <Button onClick={closeDialog} className={classes.greyButton}>
                    I do not agree
                </Button>
                <Button
                    autoFocus
                    onClick={() => setStep(2)}
                    className={classes.greenButton}
                    classes={{
                        label: classes.greenButtonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.greenButtonRippleChildPulsate,
                        },
                    }}
                >
                    I agree
                </Button>
            </MuiDialogActions>
        </>
    );
    const step2Content = (
        <>
            <Typography variant="h1" className={classes.dialogTitle}>
                Client Name
            </Typography>
            <MuiDialogContent className={classes.contentWrapper}>
                <Typography variant="h3" component="h2" className={classes.h3Text}>
                    Please enter your client&#39;s first and last name to get started
                </Typography>
                <div className="row" style={{ paddingBottom: 30 }}>
                    <div className="col">
                        <div className={classes.inputWrapper}>
                            <TextField
                                label="First Name*"
                                variant="outlined"
                                type="text"
                                autoComplete="given-name"
                                value={values.clientFirstName}
                                error={errors.clientFirstName !== undefined}
                                onChange={handleChange('clientFirstName')}
                                fullWidth
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textInputLabelRoot,
                                        focused: classes.textInputLabelFocused,
                                        error: classes.textInputLabelError,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.textInput,
                                        error: classes.textInputError,
                                    },
                                    inputProps: {
                                        'aria-label': 'First Name',
                                        'aria-required': 'true',
                                    },
                                }}
                            />
                        </div>
                        <div className={classes.errorMessage} role="status" aria-live="polite">
                            {errors.clientFirstName ? 'Please enter a first name' : ''}
                        </div>
                    </div>
                    <div className="col">
                        <div className={classes.inputWrapper}>
                            <TextField
                                label="Last Name*"
                                variant="outlined"
                                type="text"
                                autoComplete="family-name"
                                value={values.clientLastName}
                                error={errors.clientLastName !== undefined}
                                onChange={handleChange('clientLastName')}
                                fullWidth
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textInputLabelRoot,
                                        focused: classes.textInputLabelFocused,
                                        error: classes.textInputLabelError,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.textInput,
                                        error: classes.textInputError,
                                    },
                                    inputProps: {
                                        'aria-label': 'Last Name',
                                        'aria-required': 'true',
                                    },
                                }}
                            />
                        </div>
                        <div className={classes.errorMessage} role="status" aria-live="polite">
                            {errors.clientLastName ? 'Please enter a last name' : ''}
                        </div>
                    </div>
                </div>
            </MuiDialogContent>
            <MuiDialogActions className={classes.actionsWrapper}>
                <Button onClick={closeDialog} className={classes.greyButton}>
                    Cancel
                </Button>
                <Button
                    onClick={createNewForm}
                    className={classes.greenButton}
                    classes={{
                        label: classes.greenButtonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.greenButtonRippleChildPulsate,
                        },
                    }}
                >
                    Begin
                </Button>
            </MuiDialogActions>
        </>
    );

    return (
        <Dialog
            onClose={closeDialog}
            open={open}
            maxWidth={false}
            classes={{
                paper: classes.dialogPaper,
            }}
            TransitionProps={{
                role: 'none',
            }}
            fullWidth
        >
            <div className={`row no-gutters ${classes.closeIconWrapper}`}>
                <div className="col" />
                <div className="col d-flex align-items-end justify-content-center">
                    <div className={`${classes.processDot} ${step === 1 && classes.processDotActive}`} />
                    <div className={`${classes.processDot} ${step === 2 && classes.processDotActive}`} />
                </div>
                <div className="col text-right">
                    <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div className={classes.dialogWrapper}>
                {step === 1 && step1Content}
                {step === 2 && step2Content}
            </div>
        </Dialog>
    );
};

NewFormDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles, InputStyles);

export default withStyles(combinedStyles)(NewFormDialog);
