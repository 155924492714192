import Colors from '../../../../../styles/colors';

const styles = theme => ({
    textField: {
        color: Colors.charcoal,
        fontWeight: theme.typography.fontWeightMedium,
    },
});

export default styles;
