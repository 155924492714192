import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Login, ResetPassBegin, LoginBegin } from '../../actions/auth';

import CombineStyles from '../../utils/combine-styles';
import InputStyles from '../../styles/inputs';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import Validation from '../../utils/validation-schema-login';

import Logo from '../../images/myPadilla-logo-2x.png';
import LoginGraphic from '../../images/myPadilla-graphic-login-2x.png';

import useEventListener from '../../utils/use-event-listener';

const SignInForm = ({ classes }) => {
    const loginError = useSelector(state => state.auth.error);
    const loginErrorMsg = useSelector(state => state.auth.errorMsg);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const resetpassCompleted = useSelector(state => state.auth.resetPass.completed);
    const resetpassError = useSelector(state => state.auth.resetPass.error);
    const dispatch = useDispatch();

    const history = useHistory();

    const [errorAPI, setErrorAPI] = useState(null);
    const [errorMsgAPI, setErrorMsgAPI] = useState(null);
    const [values, setValues] = React.useState({ password: '', email: '' });
    const [errors, setErrors] = useState({});
    const [validationActive, setValidationActive] = useState(false);

    useEffect(() => {
        document.title = 'myPadilla: Log in';
        // clear errors on mount/dismount
        dispatch(LoginBegin({ error: false, errorMsg: '' }));
        return () => dispatch(LoginBegin({ error: false, errorMsg: '' }));
    }, []);

    useEffect(() => {
        setErrorAPI(loginError);
        if (loginError) {
            setErrorMsgAPI(loginErrorMsg);
        }
    }, [loginError]);

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        let timer = null;
        if (resetpassCompleted) {
            timer = setTimeout(() => {
                dispatch(ResetPassBegin());
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [resetpassCompleted]);

    const handleChange = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const validate = () => {
        setValidationActive(true);

        const validationError = Validation.validate({ email: values.email, password: values.password }, { abortEarly: false }).error;

        if (validationError) {
            const currentErrors = {};
            validationError.details.forEach((detail) => {
                currentErrors[detail.context.key] = detail.type;
            });

            setErrors(currentErrors);
        } else {
            setErrors({});
        }
        return validationError;
    };

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [
        values.email,
        values.password,
    ]);

    const handleSubmit = () => {
        if (!validate()) {
            // no error
            // let's make an API Call
            dispatch(Login(values));
        }
    };

    const inputRef = useRef(null);
    const eventHandler = useCallback((event) => {
        // check to see if we are pressing the enter key
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            handleSubmit();
        }
    }, [handleSubmit]);
    useEventListener('keyup', eventHandler, inputRef.current);
    return (
        <main className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 50 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196 }} />
                </div>
            </div>
            <div role="status" aria-live="polite">
                {errorAPI ? (<Typography variant="body1" className={classes.errorMessage} style={{ paddingBottom: 30 }}>{errorMsgAPI}</Typography>) : null}
                {(resetpassCompleted && !resetpassError) && (<Typography variant="body1" className={classes.successMessage} style={{ paddingBottom: 30 }}>Success: Your password has successfully been updated.</Typography>)}
            </div>
            <div className={`row ${classes.rowWrapper}`}>
                <div className={`col-12 col-md-6 text-center ${classes.formWrapper}`}>
                    <Typography variant="h4" component="h1" style={{ paddingBottom: 14 }}>Log In</Typography>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            autoComplete="on"
                            value={values.email}
                            error={errors.email !== undefined}
                            onChange={handleChange('email')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Email',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.email ? 'Please enter your email address' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            ref={inputRef}
                            aria-label="Password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            autoComplete="current-password"
                            fullWidth
                            value={values.password}
                            error={errors.password !== undefined}
                            onChange={handleChange('password')}
                            id="passwordField"
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Password',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.password ? 'Please enter your password' : ''}
                    </div>
                    <div className={`row no-gutters ${classes.forgotPasswordWrapper}`}>
                        <div className="col-auto">
                            <div style={{ paddingTop: 14 }}>
                                <Link to="/login/forgot-password" className={classes.textLink} style={{ color: 'black' }}>
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>
                        <div className="col" style={{ textAlign: 'right' }}>
                            <button
                                type="button"
                                className={classes.greenButton}
                                onClick={handleSubmit}
                            >
                                Log In
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`col-6 d-none d-md-flex justify-content-end align-items-end ${classes.leftSideWrapper}`}>
                    <img src={LoginGraphic} className={classes.loginGraphic} alt="" />
                </div>
            </div>
            <div className="row" style={{ paddingTop: 37, alignItems: 'center' }}>
                <div className="col-auto" style={{ paddingRight: 0 }}>
                    <Typography variant="h3" component="h2" className={classes.needAccountLink}>Don&apos;t have an account?</Typography>
                </div>
                <div
                    className="col"
                    style={{
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingLeft: 6,
                        paddingTop: 1,
                    }}
                >
                    <Link to="/login/signup" className={classes.textLink}>
                        Sign up
                    </Link>
                </div>
            </div>
        </main>
    );
};

SignInForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, InputStyles, ButtonStyles);

export default withStyles(combinedStyles)(SignInForm);
