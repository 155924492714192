import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ForgotPass, ForgotPassBegin } from '../../actions/auth';

import CombineStyles from '../../utils/combine-styles';
import InputStyles from '../../styles/inputs';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import Validation from '../../utils/validation-schema-forgot-pass';

import Logo from '../../images/myPadilla-logo-2x.png';
import LoginGraphic from '../../images/myPadilla-graphic-login-2x.png';

const ForgotPassword = ({ classes }) => {
    const forgotpassError = useSelector(state => state.auth.forgotPass.error);
    const forgotpassErrorMsg = useSelector(state => state.auth.forgotPass.errorMsg);
    const forgotpassCompleted = useSelector(state => state.auth.forgotPass.completed);
    const dispatch = useDispatch();

    const [values, setValues] = React.useState({ email: '' });
    const [errors, setErrors] = useState({});
    const [validationActive, setValidationActive] = useState(false);

    useEffect(() => {
        document.title = 'myPadilla: Forgot Password';
        // clear error messages when component loads
        dispatch(ForgotPassBegin());
    }, []);

    const handleChange = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const validate = () => {
        setValidationActive(true);

        const validationError = Validation.validate({ email: values.email }).error;

        if (validationError) {
            const currentErrors = {};
            validationError.details.forEach((detail) => {
                currentErrors[detail.context.key] = detail.type;
            });

            setErrors(currentErrors);
        } else {
            setErrors({});
        }
        return validationError;
    };

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [
        values.email,
    ]);

    const handleSubmit = () => {
        if (!validate()) {
            // no error
            // let's make an API Call
            dispatch(ForgotPass(values.email));
        }
    };

    return (
        <main className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 40 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196, paddingBottom: 24 }} />
                </div>
            </div>
            <div role="status" aria-live="polite">
                {forgotpassError ? (<Typography variant="body1" className={classes.errorMessage} style={{ paddingBottom: 30 }}>{forgotpassErrorMsg}</Typography>) : null}
                {(forgotpassCompleted && !forgotpassError) && (<Typography variant="body1" className={classes.successMessage} style={{ paddingBottom: 30 }}>Success: A reset email has been sent to your account.</Typography>)}
            </div>
            <div className="row" style={{ flexWrap: 'nowrap' }}>
                <div className={`col text-center ${classes.formWrapper}`}>
                    <Typography variant="h4" component="h1" style={{ paddingBottom: 14 }}>Forgot your password?</Typography>
                    <Typography variant="body1" style={{ paddingBottom: 14 }}>Enter your email address to reset your password. </Typography>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            autoComplete="on"
                            value={values.email}
                            error={errors.email !== undefined}
                            onChange={handleChange('email')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Email',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.email ? 'Please enter your email address' : ''}
                    </div>
                    <div className={`row no-gutters ${classes.forgotPasswordWrapper}`}>
                        <div className="col" style={{ textAlign: 'right' }}>
                            <button
                                type="button"
                                className={classes.greenButton}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`col d-none d-md-flex justify-content-end align-items-end ${classes.leftSideWrapper}`}>
                    <img src={LoginGraphic} className={classes.loginGraphic} alt="" />
                </div>
            </div>
            <div className="row" style={{ paddingTop: 37, alignItems: 'center' }}>
                <div className="col-auto" style={{ paddingRight: 0 }}>
                    <Typography variant="h3" component="h2" className={classes.needAccountLink}>Take me back to</Typography>
                </div>
                <div
                    className="col"
                    style={{
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingLeft: 6,
                        paddingTop: 1,
                    }}
                >
                    <Link to="/login" className={classes.textLink}>
                        Log in
                    </Link>
                </div>
            </div>
        </main>
    );
};

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, InputStyles, ButtonStyles);

export default withStyles(combinedStyles)(ForgotPassword);
