import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Dashboard from '../../routes/admin/dashboard';
import AllForms from '../../routes/admin/all-intakes';
import withRoot from '../../wiring/with-root';
import AdminHeader from '../../components/admin/header';
import AdminFooter from '../../components/admin/footer';

const AdminLayout = () => {
    const location = useLocation();
    return (
        <React.Fragment>
            <AdminHeader />
            <main style={{ minHeight: '100vh', backgroundColor: '#f2f4f6', paddingBottom: 80 }}>
                <Switch location={location}>
                    <Route exact path="/">
                        <Dashboard />
                    </Route>
                    <Route exact path="/all-intakes">
                        <AllForms />
                    </Route>
                </Switch>
            </main>
            <AdminFooter />
        </React.Fragment>
    );
};
export default withRoot(AdminLayout);
