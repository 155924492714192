import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

import _ from 'lodash';

import TextField from '@material-ui/core/TextField';
import useDebounce from '../../../../../utils/use-debounce';

import CombineStyles from '../../../../../utils/combine-styles';
import InputStyles from './input-styles';
import ButtonStyles from '../../../../../styles/buttons';


const RadioComponent = ({
    classes,
    questionProps,
    manageFormInputs,
}) => {
    const {
        choices,
        id,
        userInput,
        label,
    } = questionProps;

    // START HANDLE "OTHER" TEXT FIELD
    const [inputValue, setInputValue] = useState(userInput);
    const debouncedInputValue = useDebounce(inputValue, 500, true);
    const [otherSelected, setOtherSelected] = useState(false);

    // when the component renders, determine if we need to handle "Other"
    useEffect(() => {
        // check out choices for the existance of "Other"
        const selectedIndex = _.findIndex(choices, { value: 'Other' });
        // determin if "Other" exist, and if it's selected
        if (selectedIndex !== -1 && choices[selectedIndex].isSelected) {
            setOtherSelected(true);
        } else {
            setOtherSelected(false);
        }

        // hydrate the textfield with prop value
        setInputValue(userInput);
    }, [userInput, choices]);

    useEffect(() => {
        manageFormInputs({ id, userInput: debouncedInputValue });
    },
    [debouncedInputValue]);

    const handleTextChange = (event) => {
        setInputValue(event.target.value);
    };
    // END HANDLE "OTHER" TEXT FIELD

    const handleChange = (value) => {
        const selectedIndex = _.findIndex(choices, { value });
        const updateChoices = [...choices];
        for (let i = 0; i < updateChoices.length; i += 1) {
            if (i === selectedIndex) {
                updateChoices[i].isSelected = true;
            } else {
                updateChoices[i].isSelected = false;
            }
        }
        if (value === 'Other') {
            // if other, clear the input value so we have an empty textfield for the user
            // TODO: choices aren't currently being mapped anywhere
            manageFormInputs({ id, userInput: '', choices: updateChoices });
        } else {
            manageFormInputs({ id, userInput: value, choices: updateChoices });
        }
    };

    return (
        <>
            <RadioGroup aria-label={label} component="fieldset" className={classes.questionInputWrapper} style={{ flexDirection: 'row' }}>
                {choices.map(choice => (
                    <FormControlLabel
                        key={choice.text}
                        value={choice.text}
                        control={(
                            <Button
                                onClick={() => handleChange(choice.value)}
                                className={`${classes.whiteButton}  ${choice.isSelected && classes.whiteButtonSelected}`}
                                classes={{
                                    label: classes.whiteButtonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.whiteButtonRippleChildPulsate,
                                    },
                                }}
                            >
                                {choice.text}
                            </Button>
                        )}
                    />
                ))}
            </RadioGroup>
            {otherSelected && (
                <TextField
                    placeholder="Other..."
                    className={classes.textField}
                    variant="outlined"
                    value={inputValue}
                    onChange={handleTextChange}
                    InputProps={{
                        classes: {
                            root: classes.textField,
                        },
                        inputProps: {
                            'aria-label': 'Other value',
                        },
                    }}
                />
            )}
        </>
    );
};

RadioComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        choices: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        userInput: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
RadioComponent.defaultProps = {
    questionProps: {},
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles);
export default withStyles(combinedStyles)(RadioComponent);
