import Colors from '../../../../styles/colors';

const styles = () => ({
    headerWrapper: {
        backgroundColor: Colors.darkJungleGreen,
        height: 70,
        '@media (min-width: 1300px)': {
            paddingLeft: 45,
            paddingRight: 45,
        },
        '@media (min-width: 1600px)': {
            paddingLeft: 90,
            paddingRight: 90,
        },
    },
    wrapper: {
        paddingTop: 60,
        paddingBottom: 60,
    },
    logo: {
        height: 40,
        paddingRight: 15,
    },
    formWrapper: {
        maxWidth: 300,
        height: 340,
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: '2px 2px 3px 0 rgba(0,0,0,0.1)',
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    formDisabled: {
        '& $formNumber': {
            backgroundColor: '#cbd3dd',
        },
        '& $formStatus, $formTitle, $formSummary, $clientRequiredWrapper': {
            opacity: 0.5,
        },
    },
    formTitle: {
        paddingTop: 10,
        paddingBottom: 30,
    },
    formStatus: {
        paddingTop: 40,
    },
    formNumber: {
        backgroundColor: Colors.charcoal,
        color: 'white',
        height: 40,
        width: 40,
        borderRadius: 40,
        fontSize: 18,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -20,
    },
    formNumberGreen: {
        backgroundColor: Colors.mediumAquamarine,
    },
    formDoneIcon: {
        color: 'white',
        fill: 'white',
    },
    formSummary: {
        color: Colors.darkElectricBlue,
        fontWeight: 400,
        height: 85,
    },
    clientRequiredWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 15,
    },
    clientRequiredTxt: {
        color: Colors.darkJungleGreen,
        display: 'inline',
    },
    clientRequiredImg: {
        marginRight: 15,
        height: 24,
        width: 'auto',
        display: 'inline',
    },
    // Dialog styles
    dialogWrapper: {
        padding: '0 80px 50px 80px',
        backgroundColor: Colors.antiFlashWhite,
    },
    dialogPaper: {
        maxWidth: 680,
    },
    closeIconWrapper: {
        width: '100%',
        backgroundColor: Colors.antiFlashWhite,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
    },
    contentWrapper: {
        textAlign: 'center',
        padding: 0,
        overflowX: 'hidden',
    },
    dialogTitle: {
        textAlign: 'center',
        paddingTop: 30,
    },
    h3Text: {
        color: Colors.darkJungleGreen,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 400,
    },
    requiredAsterisk: {
        color: Colors.darkRed,
    },
    error: {
        color: Colors.darkRed,
        paddingTop: 5,
    },
    tosLink: {
        color: Colors.charcoal,
        '&:hover, &:focus': {
            color: Colors.mediumAquamarine,
        },
    },
    // END Dialog Styles
});
export default styles;
