import Colors from '../../../styles/colors';

const styles = () => ({
    inputRoot: {
        backgroundColor: 'white',
        fontSize: 15,
        fontWeight: 400,
        fontFamily: 'Arial, Helvetica, sans-serif',
        '&:hover:not($disabled):not($focused):not($error) $inputNotchedOutline': {
            border: `1px solid ${Colors.mediumAquamarine}`,
        },
    },
    disabled: {},
    focused: {},
    error: {},
    inputLabelFocused: {},
    chipWrapper: {
        backgroundColor: Colors.platinum,
        borderColor: Colors.lightGrey,
        borderRadius: 4,
        margin: 3,
    },
});
export default styles;
