import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// eslint-disable-next-line no-unused-vars
import _, { every, some } from 'lodash'; // we reference these methods dyanamically

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CheckBoxes from './components/checkboxes';
import Radio from './components/radio';
import AutoComplete from './components/autocomplete'; // used to be select
import Select from './components/select';
import TextField from './components/text-field';
import DatePicker from './components/date-picker';
import DateSelectMMDDYYYY from './components/select-mm-dd-yyyy';
import DateSelectMMYYYY from './components/select-mm-yyyy';
import FileUploader from './components/file-uploader';
import NoteToAttorney from './components/html';

import CombineStyles from '../../../../utils/combine-styles';
import Styles from './styles';
import InputStyles from './components/input-styles';
import ButtonStyles from '../../../../styles/buttons';

import LogoFile from '../images/mypadilla-icon-2x.png';

import ErrorBanner from '../../../../components/feedback-banners/error';

import { UpdateIntake } from '../../../../actions/intakes';

const QuestionFlow = ({ classes }) => {
    const { id, page } = useParams();
    const intakes = useSelector(state => state.intakes.intakes);
    const [currentIntake, setCurrentIntake] = useState({ answers: { pages: [] } });

    const [allFormInputs, setAllFormInputs] = useState([]); // used by conditional logic eval
    const [formInputs, setFormInputs] = useState([]);
    const [filteredFormInputs, setFilteredFormInputs] = useState([]);
    const [validateActive, setValidateActive] = useState(false);
    const [validateError, setValidationError] = useState(false);
    const [openToolTips, setOpenToolTips] = useState([]);

    const history = useHistory();
    const dispatch = useDispatch();

    // initial page load
    useEffect(() => {
        document.title = 'myPadilla: Intake - Question Flow';
    }, []);

    // digest URL params, setup form data
    useEffect(() => {
        // no id, so send user to dashboard
        if (!id) {
            history.push('/');
        } else {
            // look into the intakes and find the one we are trying to display
            // note: id comes in as a string from the param in url
            const tempIntake = _.find(intakes, form => form.id === parseInt(id, 10));
            // if we found it, load it.
            // else, if we can't find it, do something?
            if (tempIntake !== undefined) {
                setCurrentIntake(tempIntake);

                // pages is an array (starting with 0), page in the URL starts at 1
                setFormInputs(tempIntake.answers.pages[page - 1].questions);

                // used for evauluating conditional logic
                let tempArrayBuilder = [];
                for (let i = 0; i < tempIntake.answers.pages.length; i += 1) {
                    tempArrayBuilder = tempArrayBuilder.concat(tempIntake.answers.pages[i].questions);
                }
                setAllFormInputs(tempArrayBuilder);
            }
        }
    }, [intakes, id, page]);

    const handleToolTipOpenClose = (name) => {
        if (_.includes(openToolTips, name)) {
            // remove from the array
            const toolTipArray = [...openToolTips];
            const index = toolTipArray.indexOf(name);

            toolTipArray.splice(index, 1);

            setOpenToolTips(toolTipArray);
        } else {
            // add to the array
            let toolTipArray = [...openToolTips];
            toolTipArray = toolTipArray.concat(name);
            // send updates to the parent component
            setOpenToolTips(toolTipArray);
        }
    };

    // validator
    const validate = () => {
        let error = false;
        for (let i = 0; i < filteredFormInputs.length; i += 1) {
            if (filteredFormInputs[i].isRequired) {
                if (typeof filteredFormInputs[i].userInput === 'string' && (filteredFormInputs[i].userInput === '' || filteredFormInputs[i].userInput === null)) {
                    error = true;
                } else if (typeof filteredFormInputs[i].userInput === 'object') {
                    if (Array.isArray(filteredFormInputs[i].userInput) && (filteredFormInputs[i].userInput.length <= 0 || filteredFormInputs[i].userInput === null)) {
                        error = true;
                    }
                    if (!Array.isArray(filteredFormInputs[i].userInput) && (!filteredFormInputs[i].userInput || !filteredFormInputs[i].userInput.value || filteredFormInputs[i].userInput.value === '')) {
                        error = true;
                    }
                }
            }
        }
        setValidateActive(error);
        setValidationError(error);
        return !error;
    };

    const evaulateCondition = (logic) => {
        const internals = {};
        internals.operators = {
            is: (x, y) => x === y,
            isnot: (x, y) => x !== y,
        };

        // Array method to use to evaluate the rules
        const evaluator = (logic.logicType === 'any') ? 'some' : 'every';

        // Evaluate the rules -- in the case of 'all', this is logic.rules.every(rule)
        return logic.rules[evaluator]((rule) => {
            const operator = internals.operators[rule.operator];
            const desiredAnswer = rule.value;
            const actualAnswerIndex = _.findIndex(allFormInputs, { id: parseInt(rule.fieldId, 10) });

            // can't find the question we are looking for, fail quietly
            if (actualAnswerIndex === -1) {
                return false;
            }

            const { conditionalHidden, userInput } = allFormInputs[actualAnswerIndex];

            // If there's no familiar operator or we don't have an answer, this rule doesn't match
            if (!operator || typeof userInput === 'undefined' || conditionalHidden) {
                return false;
            }

            // if it's a checkbox, answer is going to be an array
            if (Array.isArray(userInput)) {
                return userInput.indexOf(desiredAnswer) > -1;
            }
            // if object (coming from autocomplete)
            if (typeof userInput === 'object') {
                if (userInput && userInput.value) return operator(desiredAnswer, userInput.value);
                return false;
            }

            return operator(desiredAnswer, userInput);
        });
    };

    const saveForm = (status) => {
        // duplicate our current data so we don't mutate state
        const tempIntake = _.cloneDeep(currentIntake);

        // Find item index
        // NOTE: page number has to be reduced to access the page in the array
        const index = _.findIndex(currentIntake.answers.pages, { pageNumber: parseInt(page - 1, 10) });

        // isolate the data we are going to be working with
        const currentPage = tempIntake.answers.pages[index];

        // Clean the date fields to prevent Moment error from making it to database
        const newFormInputs = [...formInputs];
        for (let i = 0; i < newFormInputs.length; i += 1) {
            if (newFormInputs[i].type === 'datepicker' && newFormInputs[i].userInput === 'Invalid date') {
                newFormInputs[i].userInput = '';
            }
        }

        // Replace item at index using native splice
        tempIntake.answers.pages.splice(index, 1, { ...currentPage, status, questions: newFormInputs });

        if (status === 'complete') {
            if (validate()) {
                dispatch(UpdateIntake(tempIntake));
            }
        } else { // draft
            dispatch(UpdateIntake(tempIntake));
        }
    };

    // receives data from individual inputs
    const manageFormInputs = useCallback((value) => {
        // duplicate our current data so we don't mutate state
        const newFormInputs = [...formInputs];

        // Find item index using _.findIndex
        const index = _.findIndex(newFormInputs, { id: value.id });

        // isolate the data we are going to be working with
        const currentInput = newFormInputs[index];

        // Replace item at index
        // some items come with more than one paremeters in value, so we use a spread operator
        newFormInputs.splice(index, 1, { ...currentInput, ...value });

        // update state with new values
        setFormInputs(newFormInputs);

        // used for evauluating conditional logic
        const tempIntake = _.find(intakes, form => form.id === parseInt(id, 10));
        let tempArrayBuilder = [];
        for (let i = 0; i < tempIntake.answers.pages.length; i += 1) {
            if (i === (page - 1)) {
                // merge current page's updated within global set of questions used for conditional logic
                tempArrayBuilder = tempArrayBuilder.concat(newFormInputs);
            } else {
                tempArrayBuilder = tempArrayBuilder.concat(tempIntake.answers.pages[i].questions);
            }
        }
        setAllFormInputs(tempArrayBuilder);
    }, [formInputs]);

    const clearBanner = () => {
        setValidationError(false);
    };

    // listens for updates to in
    // _.findIndex(sectionHeaders, { group: question.group }) === -1
    useEffect(() => {
        const sectionHeaders = [];

        // create filtered list of what should be shown
        let filteredInputs = formInputs.map((question, index) => {
            // trap questions that shouldn't be shown
            if (question.conditionalLogic && question.conditionalLogic.rules && question.conditionalLogic.rules.length > 0) {
                if (!evaulateCondition(question.conditionalLogic)) {
                    // if rules evaluate to false, then we should not show the question
                    formInputs[index].conditionalHidden = true;
                    return false;
                }
                // this ensures that something that was hidden, but then revealed doesn't stay marked as hidden for the back end
                formInputs[index].conditionalHidden = false;
            }

            // build list of visible sections for downstream filterintg
            if (question.group && question.type === 'section') {
                sectionHeaders.push(question.group);
            }

            return question;
        });

        // items that are false are excluded items based on conditional logic
        filteredInputs = filteredInputs.filter(item => item !== false);

        // items that have a group, and have a question of type section accounted for
        filteredInputs = filteredInputs.filter(item => !item.group || (item.group && _.includes(sectionHeaders, item.group)));

        setFilteredFormInputs(filteredInputs);
    }, [formInputs]);

    useEffect(() => {
        // if we are in validation mode, keep checking as the inputs change
        if (validateActive) validate();
    }, [filteredFormInputs]);

    return (
        <>
            <header className={`container-fluid ${classes.headerWrapper}`}>
                <div className="row align-items-center" style={{ height: '100%' }}>
                    <div className="col d-flex flex-direction-row align-items-center">
                        <img src={LogoFile} alt="" className={classes.logo} />
                        <Typography variant="h2" style={{ color: 'white' }}>
                            {currentIntake.clientLastName && currentIntake.clientLastName}
                            ,&nbsp;
                            {currentIntake.clientFirstName && currentIntake.clientFirstName}
                        </Typography>
                        <span className={classes.questionFlowHeaderMark}>&gt;</span>
                        <Typography variant="h2" style={{ color: 'white' }}>
                            Step&nbsp;
                            {page}
                            :&nbsp;
                            {currentIntake.answers.pages[page - 1] && currentIntake.answers.pages[page - 1].pageTitle}
                        </Typography>
                    </div>
                    <div className="col text-right">
                        <Button className={classes.greyButton} onClick={() => saveForm('draft')} style={{ marginRight: 15 }}>
                            Save as Draft
                        </Button>
                        <Button
                            className={classes.greenButton}
                            onClick={() => saveForm('complete')}
                            classes={{
                                label: classes.greenButtonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.greenButtonRippleChildPulsate,
                                },
                            }}
                        >
                            Complete Step&nbsp;
                            {page}
                        </Button>
                    </div>
                </div>
            </header>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="container" style={{ paddingTop: 130, paddingBottom: 50 }}>
                    <div style={{ maxWidth: 690, margin: '0 auto' }} role="status" aria-live="polite">
                        {validateError && (
                            <ErrorBanner text="You need to complete the fields highlighted below to progress forward" clearBanner={clearBanner} />
                        )}
                    </div>
                    {filteredFormInputs.map((question, i) => {
                        let questionComponent;
                        let error = false;

                        // if validation is active, add error flag to empty questions that are required
                        if (validateActive && question.isRequired) {
                            if (typeof filteredFormInputs[i].userInput === 'string' && (filteredFormInputs[i].userInput === '' || filteredFormInputs[i].userInput === null)) {
                                error = true;
                            } else if (typeof filteredFormInputs[i].userInput === 'object') {
                                if (Array.isArray(filteredFormInputs[i].userInput) && (filteredFormInputs[i].userInput.length <= 0 || filteredFormInputs[i].userInput === null)) {
                                    error = true;
                                }
                                if (!Array.isArray(filteredFormInputs[i].userInput) && (!filteredFormInputs[i].userInput || !filteredFormInputs[i].userInput.value || filteredFormInputs[i].userInput.value === '')) {
                                    error = true;
                                }
                            }
                        }
                        if (question.type === 'checkbox') {
                            questionComponent = <CheckBoxes questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'radio') {
                            questionComponent = <Radio questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'select' && typeof question.userInput === 'string') {
                            // this component is a legacy element, we include it here to continue support for old intakes
                            questionComponent = <Select questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'select' && typeof question.userInput === 'object') {
                            questionComponent = <AutoComplete questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'text' || question.type === 'number') {
                            questionComponent = <TextField questionProps={question} error={error} manageFormInputs={manageFormInputs} multiline={false} />;
                        } else if (question.type === 'textarea') {
                            questionComponent = <TextField questionProps={question} error={error} manageFormInputs={manageFormInputs} multiline />;
                        } else if (question.type === 'datepicker') {
                            questionComponent = <DatePicker questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'datedropdown') {
                            questionComponent = <DateSelectMMDDYYYY questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'datefield') {
                            questionComponent = <DateSelectMMYYYY questionProps={question} error={error} manageFormInputs={manageFormInputs} />;
                        } else if (question.type === 'section') {
                            questionComponent = '';
                        } else if (question.type === 'html') {
                            questionComponent = <NoteToAttorney questionProps={question} />;
                        } else if (question.type === 'fileupload') {
                            questionComponent = <FileUploader questionProps={question} error={error} manageFormInputs={manageFormInputs} intakeId={id} />;
                        } else {
                            return false;
                        }

                        return (
                            <span key={`question-${question.id}`} id={`question ${question.id}`}>
                                {_.includes(openToolTips, question.id) && (
                                    <div className={`row ${classes.toolTipWrapper}`}>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div className="col" contentEditable="false" dangerouslySetInnerHTML={{ __html: question.toolTip }} />
                                        <div className="col-1 text-right">
                                            <IconButton aria-label="close tooltip" className={classes.closeButton} onClick={() => handleToolTipOpenClose(question.id)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`row no-gutters ${classes.questionWrapper}
                                                    ${error ? classes.questionWrapperError : ''} {/* if there is an error */}
                                                    ${question.group ? classes.caseWrapper : ''} {/* if we are inside a case */}
                                                    ${(question.group && filteredFormInputs[i - 1] && (question.group !== filteredFormInputs[i - 1].group)) ? classes.caseTop : ''} {/* if this is the last question in a case */}
                                                    ${(question.group && filteredFormInputs[i + 1] && (question.group !== filteredFormInputs[i + 1].group)) ? classes.caseBottom : ''} {/* if this is the last question in a case */}
                                                    ${question.type === 'section' ? classes.sectionWrapper : ''} {/* if the question type is a section */}
                                                    ${question.type === 'html' ? classes.htmlWrapper : ''} {/* if the question type is a html */}
                                                `}
                                >
                                    {question.type !== 'html' && (// HTML block is used to create "note to attorney" and requires unique structure
                                        <div className="col">
                                            <Typography variant="h2" className={classes.questionTitle}>
                                                {question.label}
                                                {question.isRequired && <sup className={classes.requiredAsterisk}>*</sup>}
                                            </Typography>
                                            {question.description && <Typography variant="h3" className={classes.questionDescription}>{question.description}</Typography>}
                                            {questionComponent}
                                            <div role="status" aria-live="polite">
                                                {error && <Typography variant="body2" className={classes.error}>Required Input</Typography>}
                                            </div>
                                        </div>
                                    )}
                                    {question.type === 'html' && (
                                        <div className="col">
                                            {questionComponent}
                                        </div>
                                    )}
                                    {question.toolTip && (
                                        <div className="col-1 text-right">
                                            <IconButton
                                                aria-label="Open Tool Tip"
                                                onClick={() => handleToolTipOpenClose(question.id)}
                                            >
                                                <HelpIcon className={classes.toolTipIcon} />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            </span>
                        );
                    })}
                </div>
            </MuiPickersUtilsProvider>
        </>
    );
};

QuestionFlow.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, InputStyles, ButtonStyles);
export default withStyles(combinedStyles)(QuestionFlow);
