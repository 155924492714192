import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import withRoot from '../../wiring/with-root';

import Login from '../../routes/login';
import ForgotPassword from '../../routes/login/forgot-password';
import ResetPassword from '../../routes/login/reset-password';
import SignUp from '../../routes/login/signup';
import SignUpSuccess from '../../routes/login/signup-success';
import Terms from '../../routes/public/terms-and-conditions';

const PublicLayout = () => {
    const location = useLocation();

    return (
        <main className="container">
            <Switch location={location}>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/login/forgot-password">
                    <ForgotPassword />
                </Route>
                <Route path="/password-reset/:resetToken">
                    <ResetPassword />
                </Route>
                <Route path="/password-reset/">
                    <Login />
                </Route>
                <Route path="/login/signup/success">
                    <SignUpSuccess />
                </Route>
                <Route path="/login/signup">
                    <SignUp />
                </Route>
                <Route path="/terms">
                    <Terms />
                </Route>
            </Switch>
        </main>
    );
};

export default withRoot(PublicLayout);
