import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CombineStyles from '../../../../../utils/combine-styles';
import Styles from './select-styles';
import InputStyles from './input-styles';

// TODO: check that this will work with mobile browsers as expected
const SelectComponent = ({
    classes,
    questionProps,
    manageFormInputs,
}) => {
    const {
        choices,
        id,
        userInput,
        inputMask,
        label,
    } = questionProps;

    const handleChange = (event, newValue) => {
        // note, we are sending an full object not just a string
        if (newValue) manageFormInputs({ id, userInput: newValue });
        else manageFormInputs({ id, userInput: null });
    };
    return (

        <FormGroup component="fieldset" className={classes.questionInputWrapper}>
            <InputLabel id={id.toString()} className="screen-reader-only">{label}</InputLabel>
            <Autocomplete
                options={choices}
                getOptionLabel={choice => choice.text}
                style={{ width: 300 }}
                onChange={handleChange}
                value={userInput}
                debug
                renderInput={params => (
                    <TextField {...params} label={inputMask} variant="outlined" fullWidth />
                )}
            />
        </FormGroup>
    );
};

SelectComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        choices: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        userInput: PropTypes.object,
        inputMask: PropTypes.string,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
SelectComponent.defaultProps = {
    questionProps: {
        inputMask: '',
        userInput: {},
    },
};

const combinedStyles = CombineStyles(Styles, InputStyles);
export default withStyles(combinedStyles)(SelectComponent);
