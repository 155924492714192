import Colors from '../../../../../styles/colors';

const styles = () => ({
    wrapper: {
        paddingTop: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paginationArrow: {
        padding: '0 15px',
    },
    pageNumberBtn: {
        height: 40,
        width: 40,
        borderRadius: 40,
        '&:disabled': {
            opacity: 1,
            color: 'white',
            backgroundColor: Colors.mediumAquamarine,
        },
    },
});
export default styles;
