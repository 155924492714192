import Colors from '../../../../styles/colors';

const styles = () => ({
    headerWrapper: {
        backgroundColor: Colors.darkJungleGreen,
        height: 70,
        position: 'fixed',
        zIndex: 100,
    },
    logo: {
        height: 40,
        paddingRight: 15,
    },
    questionFlowHeaderMark: {
        color: Colors.darkElectricBlue,
        paddingLeft: 15,
        paddingRight: 15,
    },
});

export default styles;
