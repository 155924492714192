import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Styles from './styles';

const currentYear = new Date().getFullYear();

const AdminIntakeFooter = ({ classes }) => (
    <footer className={`container-fluid ${classes.wrapperIntake}`}>
        <div className="row align-items-center" style={{ height: '100%' }}>
            <div className="col text-center">
                <Typography variant="subtitle1" className={classes.copyRightText}>
                Copyright &copy;&nbsp;
                    {currentYear}
                &nbsp;myPadilla. All rights reserved.
                </Typography>
            </div>
        </div>
    </footer>
);

AdminIntakeFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(AdminIntakeFooter);
