import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Styles from './styles';

const SuccessBanner = ({ classes, text, clearBanner }) => (
    <div className={`row ${classes.bannerWrapper}`}>
        <div className={`col-auto ${classes.successIconWrapper}`}>
            <CheckIcon className={classes.icon} />
        </div>
        <div className="col">
            <Typography variant="body1">{text}</Typography>
        </div>
        <div className="col-auto">
            <button type="button" onClick={clearBanner}><CloseIcon /></button>
        </div>
    </div>
);
SuccessBanner.propTypes = {
    classes: PropTypes.object.isRequired,
    clearBanner: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default withStyles(Styles)(SuccessBanner);
