import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ScalesImg from './images/mypadilla-icon-2x.png';
import Styles from './styles';

const currentYear = new Date().getFullYear();

const AdminFooter = ({ classes }) => (
    <footer className={`container-fluid ${classes.wrapper}`}>
        <div className="row align-items-center" style={{ height: '100%' }}>
            <div className="col">
                <img alt="" src={ScalesImg} style={{ height: 30, marginRight: 5 }} />
                <a className={classes.donateLink} href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=VJJETXYXKYWBQ&source=url" target="_blank" rel="noopener noreferrer">
                    Donate to myPadilla
                </a>
            </div>
            <div className="col text-center">
                <Typography variant="subtitle1" className={classes.copyRightText}>
                Copyright &copy;&nbsp;
                    {currentYear}
                &nbsp;myPadilla. All rights reserved.
                </Typography>
            </div>
            <div className="col d-none d-md-flex" />
        </div>
    </footer>
);

AdminFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(AdminFooter);
