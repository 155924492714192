import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import Styles from './html-styles';

const Html = ({ classes, questionProps }) => (
    <div className={`row ${classes.wrapper}`}>
        <div className="col-auto">
            <InfoOutlinedIcon className={classes.icon} />
        </div>
        <div className="col">
            <b>Note to Attorney:&nbsp;</b>
            <Typography variant="body1" style={{ display: 'inline' }}>
                {questionProps.description}
            </Typography>
        </div>
    </div>
);

Html.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.object.isRequired,
};

export default withStyles(Styles)(Html);
