import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { times } from 'lodash';

import Styles from './styles';

const Pagination = ({
    classes,
    page,
    itemsPerPage,
    listLength,
    setPage,
}) => {
    const [numberOfPages, setNumberOfPages] = useState(false);

    // update state based on information coming from remote source
    useEffect(() => {
        const pageCount = Math.ceil(listLength / itemsPerPage);
        setNumberOfPages(pageCount);
    }, [listLength, itemsPerPage]);

    return (
        <div className={classes.wrapper}>
            <button
                disabled={page === 0}
                type="button"
                onClick={() => setPage(page - 1)}
                className={classes.paginationArrow}
                aria-label="previous page"
            >
                <ArrowLeftIcon />
            </button>
            {times(numberOfPages, i => (
                <button
                    className={classes.pageNumberBtn}
                    disabled={page === i}
                    type="button"
                    key={i}
                    onClick={() => setPage(i)}
                >
                    {i + 1}
                </button>
            ))}
            <button
                disabled={page >= ((listLength / itemsPerPage) - 1)}
                type="button"
                onClick={() => setPage(page + 1)}
                className={classes.paginationArrow}
                aria-label="next page"
            >
                <ArrowRightIcon />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    classes: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    listLength: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
};

export default withStyles(Styles)(Pagination);
