import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import CombineStyles from '../../utils/combine-styles';
import Styles from './styles';
import ButtonStyles from '../../styles/buttons';

import Logo from '../../images/myPadilla-logo-2x.png';
import SignupGraphic from '../../images/myPadilla-graphic-signup-2x.png';

const SignUpSuccess = ({ classes }) => {
    const history = useHistory();
    const handleSubmit = () => {
        history.push('/');
    };
    useEffect(() => {
        document.title = 'myPadilla: Signup Success';
    }, []);

    return (
        <main className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 40 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196, paddingBottom: 24 }} />
                </div>
            </div>
            <div className={`row no-gutters ${classes.signupSuccessWrapper}`}>
                <div className="col" style={{ padding: '60px 30px' }}>
                    <Typography
                        variant="h1"
                        style={{
                            color: '#FFF',
                            paddingBottom: 30,
                        }}
                    >
                        Thank you for applying to myPadilla.
                    </Typography>
                    <Typography style={{
                        fontWeight: 500,
                        color: '#FFF',
                        fontSize: 14,
                        paddingBottom: 30,
                    }}
                    >
                        We&#39;ll let you know when you may begin using your account to access &nbsp;
                        <i>Padilla</i>
                        &nbsp;expertise to better serve your clients.
                    </Typography>
                    <button
                        type="button"
                        className={classes.greenButton}
                        onClick={handleSubmit}
                        aria-label="Back to Home"
                    >
                        Back to Home
                    </button>
                </div>
                <div className="col d-none d-md-flex" style={{ textAlign: 'right' }}>
                    <img src={SignupGraphic} style={{ height: '275px' }} alt="" />
                </div>
            </div>
        </main>
    );
};

SignUpSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles);

export default withStyles(combinedStyles)(SignUpSuccess);
