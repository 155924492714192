import ReactGA from 'react-ga';
import History from '../wiring/history';

const GAUtils = {};
const debug = false;
GAUtils.initGA = () => {
    ReactGA.initialize('UA-155088898-1', {
        debug,
        titleCase: false,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    // tell GA everytime the URL changes (includes hashes)
    History.listen(location => ReactGA.pageview(location.pathname + location.search));
};
export default GAUtils;

export const QuestionRecorder = (title, answer) => {
    ReactGA.event({
        category: 'Question/Response',
        action: `Q: ${title}`,
        label: answer,
    });
};
