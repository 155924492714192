export default {
    darkJungleGreen: '#0A1B2B',
    charcoal: '#2A3A4B',
    darkElectricBlue: '#5C6E81',
    cadetGrey: '#92A7BA',
    lightGrey: '#C9D3DE',
    gainsboro: '#D5DDE5',
    platinum: '#E3E8ED',
    antiFlashWhite: '#F1F4F6',
    glitter: '#E9EDF1',
    darkTangerine: '#FEB516',
    topaz: '#FFD07B',
    blush: '#D46179',
    raspberryRose: '#BB5067',
    mediumAquamarine: '#5BCE97',
    moss: '#76DEAC',
    darkRed: '#973C4F',
};
