/* eslint-disable import/prefer-default-export */
import history from '../wiring/history';
import WebClient from '../utils/web-client';
import IntakeTypes from '../action-types/intakes';

export const GetActiveFormBegin = () => ({
    type: IntakeTypes.GET_ACTIVE_FORM_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const GetActiveFormSuccess = payload => ({
    type: IntakeTypes.GET_ACTIVE_FORM_SUCCESS,
    payload,
});
const GetActiveFormError = payload => ({
    type: IntakeTypes.GET_ACTIVE_FORM_ERROR,
    payload,
});

export const GetActiveForm = () => (dispatch) => {
    dispatch(GetActiveFormBegin());
    const getIntakeActive = WebClient.get('/forms/active');

    getIntakeActive
        .then(({ data }) => {
            dispatch(GetActiveFormSuccess(data));
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                errorMsg = 'Error: Unable to process request.';
            }
            if (error.response && (error.response.status === 422)) {
                // 422 (Unprocessable Entity)
                errorMsg = 'Error: 422';
            }
            dispatch(GetActiveFormError({ error: true, errorMsg, completed: true }));
        });

    return getIntakeActive;
};

const GetIntakesBegin = () => ({
    type: IntakeTypes.GET_INTAKES_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const GetIntakesSuccess = payload => ({
    type: IntakeTypes.GET_INTAKES_SUCCESS,
    payload,
});
const GetIntakesError = payload => ({
    type: IntakeTypes.GET_INTAKES_ERROR,
    payload,
});

export const GetIntakes = () => (dispatch) => {
    dispatch(GetIntakesBegin());
    const getIntakes = WebClient.get('/intakes');

    getIntakes
        .then(({ data }) => {
            dispatch(GetIntakesSuccess(data));
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                errorMsg = 'Error: Unable to process request.';
            }
            if (error.response && (error.response.status === 422)) {
                // 422 (Unprocessable Entity)
                errorMsg = 'Error: 422';
            }
            dispatch(GetIntakesError({ error: true, errorMsg, completed: true }));
        });

    return getIntakes;
};
export const DeleteEntryBegin = () => ({
    type: IntakeTypes.DELETE_ENTRY_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const DeleteEntrySuccess = payload => ({
    type: IntakeTypes.DELETE_ENTRY_SUCCESS,
    payload,
});
const DeleteEntryError = payload => ({
    type: IntakeTypes.DELETE_ENTRY_ERROR,
    payload,
});

export const DeleteEntry = id => (dispatch) => {
    dispatch(DeleteEntryBegin());
    const deleteEntry = WebClient.delete(`/intakes/${id}`);

    deleteEntry
        .then(() => {
            dispatch(DeleteEntrySuccess({ error: false, errorMsg: '', completed: true }));
            dispatch(GetIntakes());
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                // {"errors":{"unauthorized":["Missing authentication"]}}
                errorMsg = 'Error: Unable to process request.';
            }
            if (error.response && (error.response.status === 422)) {
                errorMsg = 'Error: 422';
            }
            dispatch(DeleteEntryError({ error: true, errorMsg, completed: true }));
        });

    return deleteEntry;
};

export const CreateIntakeBegin = () => ({
    type: IntakeTypes.INTAKE_CREATE_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const CreateIntakeSuccess = payload => ({
    type: IntakeTypes.INTAKE_CREATE_SUCCESS,
    payload,
});
const CreateIntakeError = payload => ({
    type: IntakeTypes.INTAKE_CREATE_ERROR,
    payload,
});

export const CreateIntake = formValues => (dispatch) => {
    dispatch(CreateIntakeBegin());
    const createIntake = WebClient.post(
        '/intakes',
        formValues,
    );

    createIntake
        .then(({ data }) => {
            dispatch(CreateIntakeSuccess(data.id));
            dispatch(GetIntakes());
            history.push(`/intake/dashboard/${data.id}`);
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                // {"errors":{"unauthorized":["Missing authentication"]}}
                errorMsg = 'Error: Unable to process request.';
            }
            if (error.response && (error.response.status === 422)) {
                errorMsg = 'Error: 422';
            }
            dispatch(CreateIntakeError({ error: true, errorMsg, completed: true }));
        });

    return createIntake;
};


export const UpdateIntakeBegin = () => ({
    type: IntakeTypes.INTAKE_UPDATE_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const UpdateIntakeSuccess = payload => ({
    type: IntakeTypes.INTAKE_UPDATE_SUCCESS,
    payload,
});
const UpdateIntakeError = payload => ({
    type: IntakeTypes.INTAKE_UPDATE_ERROR,
    payload,
});

export const UpdateIntake = formValues => (dispatch) => {
    dispatch(UpdateIntakeBegin());

    const cleanForm = { ...formValues };

    delete cleanForm.causeNumber;
    delete cleanForm.dateSubmitted;
    delete cleanForm.createdAt;
    delete cleanForm.updatedAt;
    delete cleanForm.advisalUrl;
    delete cleanForm.summaryUrl;
    delete cleanForm.userId;
    delete cleanForm.id;

    if (cleanForm.status !== 'In Review') {
        delete cleanForm.dateRequestedBy;
        delete cleanForm.submitComments;
    } else if (!cleanForm.submitComments) {
        // going into review, but user didn't add comments
        delete cleanForm.submitComments;
    }

    const updateIntake = WebClient.patch(
        `/intakes/${formValues.id}`,
        cleanForm,
    );

    updateIntake
        .then(({ data }) => {
            dispatch(GetIntakes());
            if (data.status === 'In Review') {
                history.push('/all-intakes');
                dispatch(UpdateIntakeSuccess({ intakeSubmitted: true }));
            } else {
                history.push(`/intake/dashboard/${data.id}`);
                dispatch(UpdateIntakeSuccess({ intakeSubmitted: false }));
            }
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                // {"errors":{"unauthorized":["Missing authentication"]}}
                errorMsg = 'Error: Unable to process request.';
            }
            if (error.response && (error.response.status === 422)) {
                errorMsg = 'Error: 422';
            }
            dispatch(UpdateIntakeError({ error: true, errorMsg, completed: true }));
        });

    return updateIntake;
};

const form = (values) => {
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in values) {
        let value = values[key];

        if (value && typeof value === 'object' && !(value instanceof Blob)) {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    }

    return formData;
};

/* NOTE: This function handles response data uniquely
- the promise response is used locally in the file uploader components
- this is because identifying begin/error/success information globally in the way other data is
... led to multiple componets relying on information not related to them.
- instead each component listened directly to the promise and handles succes/error data locally
*/
// we need to inclide dispatch to treat this action like a thunk, but we don't actually need to dispatch anything
// eslint-disable-next-line no-unused-vars
export const UploadFile = payload => (dispatch) => {
    const { file, id } = payload;
    const thePayload = { file };
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    const uploadFile = WebClient.post(`/intakes/${id}/upload`, form(thePayload), config);

    uploadFile
        .then(() => {})
        .catch(err => err);
    // Pass-through the promise because components manage respose data locally
    return uploadFile;
};
