import Colors from '../../styles/colors';

const styles = () => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50,
        paddingBottom: 40,
        '@media (max-width: 768px)': {
            display: 'block',
            textAlign: 'center',
        },
    },
    rowWrapper: {
        flexWrap: 'nowrap',
        '@media (max-width: 768px)': {
            padding: '0 10px 10px 10px',
        },
    },
    loginGraphic: {
        height: 250,
        width: 250,
    },
    formWrapper: {
        padding: '32px 40px',
        backgroundColor: Colors.antiFlashWhite,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        width: 320,
        height: 'auto',
        '@media (max-width: 768px)': {
            borderRadius: 5,
        },
    },
    needAccountLink: {
        color: Colors.cadetGrey,
    },
    leftSideWrapper: {
        display: 'flex',
        flexDirection: 'column',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        width: 400,
        height: 'auto',
        backgroundColor: Colors.charcoal,
        paddingRight: 0,
    },
    signupSuccessWrapper: {
        width: 660,
        backgroundColor: Colors.charcoal,
        borderRadius: 5,
    },
    forgotPasswordWrapper: {
        margin: '28px 0 0 0',
        paddingTop: 10,
        width: '100%',
        fontWeight: 600,
    },
    textLink: {
        color: 'white',
        textDecoration: 'none',
        borderBottom: `2px solid ${Colors.mediumAquamarine}`,
        padding: 3,
        fontSize: 15,
    },
    // used for select menus
    inputLabelShrink: {
        color: `${Colors.charcoal} !important`,
    },
    inputFocus: {},
    inputNotchedOutline: {},
    inputLabelRoot: {
        fontSize: 15,
        fontFamily: 'Arial, Helvetica, sans-serif',
        color: Colors.darkElectricBlue,
        fontWeight: 400,
    },
    tosCheckbox: {
        color: Colors.charcoal,
        marginLeft: -10,
    },
    tosCheckboxChecked: {
        marginLeft: -10,
        color: Colors.mediumAquamarine,
    },
    tosLink: {
        color: Colors.charcoal,
        '&:hover, &:focus': {
            color: Colors.mediumAquamarine,
        },
    },
});
export default styles;
