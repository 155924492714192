import Colors from '../../../styles/colors';

const styles = theme => ({
    wrapper: {
        paddingTop: 30,
        paddingBottom: 60,
    },
    tableHeader: {
        paddingLeft: 15,
        paddingRight: 15,
        height: 60,
        borderRadius: '4px 4px 0 0',
        backgroundColor: Colors.lightGrey,
    },
    tableRow: {
        borderTop: '1px solid #E3E8ED',
        paddingLeft: 15,
        paddingRight: 15,
        height: 70,
        backgroundColor: 'white',
        '&:last-of-type': {
            borderRadius: '0 0 40px 40px',
        },
    },
    rowTitleText: {
        display: 'inline',
    },
    tableRowClientName: {
        fontSize: 15,
        fontWeight: 700,
    },
    tableRowCauseNumber: {
        color: Colors.darkElectricBlue,
    },
    tableRowDate: {
        color: Colors.darkElectricBlue,
    },
    statusTextDraft: {
        color: Colors.darkElectricBlue,
    },
    tableRowButton: {
        height: 30,
        backgroundColor: Colors.antiFlashWhite,
        color: Colors.darkJungleGreen,
        fontSize: 13,
        fontWeight: 400,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 1,
        display: 'inline-flex',
        '&:hover, &:focus': {
            backgroundColor: Colors.lightGrey,
        },
    },
    tableRowButtonArrow: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:hover, &:focus': {
            backgroundColor: Colors.lightGrey,
        },
    },
    tableRowExpandIcon: {
        width: 15,
        height: 'auto',
    },
    statusIcon: {
        marginRight: 5,
        display: 'inline-flex',
        backgroundColor: Colors.cadetGrey,
        height: 12,
        width: 12,
        borderRadius: 12,
    },
    statusIconDraft: {
        backgroundColor: Colors.cadetGrey,
    },
    statusIconReview: {
        backgroundColor: Colors.darkTangerine,
    },
    statusIconCompleted: {
        backgroundColor: '#17C28F',
    },
    popoverWrapper: {
        borderRadius: 0,
        width: 160,
        paddingTop: 10,
        paddingBottom: 10,
        '& button': {
            display: 'block',
        },
    },
    popoverButton: {
        width: '100%',
        padding: 10,
        paddingLeft: 30,
        textAlign: 'left',
        '&:focus, &:hover': {
            backgroundColor: Colors.gainsboro,
        },
    },
    sortWrapper: {
        display: 'inline-flex',
        flexDirection: 'column',
        paddingRight: 5,
    },
    sortBtn: {
        height: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        cursor: 'pointer',
        padding: 5,
        borderRadius: 25,
        '& img': {
            height: 6,
            margin: 3,
            cursor: 'pointer',
        },
        '&:focus': {
            padding: 4,
            border: '1px solid black',
        },
    },
    filterBtn: {
        textTransform: 'uppercase',
        color: Colors.darkElectricBlue,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.body1.fontSize,
        letterSpacing: 0.54,
        padding: '10px 15px',
        margin: '0 15px',
        '&:focus': {
            borderRadius: 25,
            border: `2px solid ${Colors.darkJungleGreen}`,
        },
    },
    filterBtnActive: {
        color: Colors.darkJungleGreen,
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `2px solid ${Colors.mediumAquamarine}`,
        '&:focus': {
            borderRadius: 25,
            border: `2px solid ${Colors.mediumAquamarine}`,
        },
    },
});
export default styles;
