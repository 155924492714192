import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

const AccountDropDown = ({ handleClose, anchorEl, children }) => {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {children}
            </Popover>
        </div>
    );
};

AccountDropDown.propTypes = {
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
};
AccountDropDown.defaultProps = {
    anchorEl: null,
};

export default AccountDropDown;
