import Colors from '../../../../../styles/colors';

const styles = theme => ({
    selectWrapper: {
        marginRight: 10,
        marginBottom: 15,
    },
    selectRoot: {
        paddingLeft: 15,
        color: Colors.charcoal,
        borderRadius: 5,
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
    },
    menuItem: {
        color: Colors.charcoal,
        fontWeight: theme.typography.fontWeightMedium,
    },
});

export default styles;
