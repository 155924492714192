import Colors from '../../../../styles/colors';

const styles = () => ({
    dialogWrapper: {
        padding: '0 80px 50px 80px',
        backgroundColor: Colors.antiFlashWhite,
    },
    dialogPaper: {
        maxWidth: 680,
    },
    closeIconWrapper: {
        width: '100%',
        backgroundColor: Colors.antiFlashWhite,
    },
    processDot: {
        height: 8,
        width: 8,
        borderRadius: 8,
        backgroundColor: '#D5DDE5',
        display: 'inline-block',
        marginRight: 5,
    },
    processDotActive: {
        backgroundColor: Colors.mediumAquamarine,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 0,
    },
    contentWrapper: {
        textAlign: 'center',
        padding: 0,
        overflowX: 'hidden',
    },
    dialogTitle: {
        textAlign: 'center',
        paddingTop: 30,
    },
    h3Text: {
        color: Colors.darkJungleGreen,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 400,
    },
    policyText: {
        color: Colors.darkJungleGreen,
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '17px',
        padding: 0,
        margin: 0,
    },
    policyTextWrapper: {
        backgroundColor: 'white',
        paddingLeft: 15,
        paddingRight: 15,
        height: 120,
        overflowY: 'scroll',
    },
    tosLink: {
        color: Colors.charcoal,
        '&:hover, &:focus': {
            color: Colors.mediumAquamarine,
        },
    },
});
export default styles;
