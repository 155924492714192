import Joi from '@hapi/joi';

const schema = Joi.object({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { allow: false } })
        .required(),
    password: Joi.string().min(8).required(),
    firm: Joi.string().required(),
    phoneNumber: Joi.string().trim().regex(/^[0-9]{7,10}$/).allow(''),
    practiceCounties: Joi.array().items(Joi.number().required()),
    practiceType: Joi.string().required(),
    termsApproved: Joi.boolean().invalid(false),
});

export default schema;
