const styles = () => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 50,
        paddingBottom: 40,
    },
    contentWrapper: {
        backgroundColor: 'white',
        borderRadius: 15,
        padding: 30,
        paddingBottom: 60,
        '& h2': {
            paddingTop: 15,
        },
        '& p': {
            paddingTop: 10,
        },
    },
});

export default styles;
