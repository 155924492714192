import Colors from '../../../../../styles/colors';

const styles = () => ({
    wrapper: {
        color: Colors.darkJungleGreen,
        alignItems: 'center',
    },
    icon: {
        color: Colors.darkTangerine,
        fill: Colors.darkTangerine,
        height: 40,
        width: 'auto',
    },
});

export default styles;
