import Colors from '../../../styles/colors';

const styles = () => ({
    dialogWrapper: {
        padding: '0 80px 50px 80px',
        backgroundColor: Colors.antiFlashWhite,
    },
    dialogPaper: {
        maxWidth: 680,
    },
    closeIconWrapper: {
        width: '100%',
        textAlign: 'right',
        backgroundColor: Colors.antiFlashWhite,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentWrapper: {
        textAlign: 'center',
    },
    nameText: {
        color: Colors.darkElectricBlue,
        backgroundColor: 'white',
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 400,
    },
    warningText: {
        color: Colors.raspberryRose,
        paddingTop: 30,
        paddingBottom: 30,
        fontWeight: 400,
    },
});
export default styles;
