import Colors from './colors';

const styles = theme => ({
    closeButton: {
        color: 'black',
        fill: 'black',
    },
    greenButton: {
        textTransform: 'none',
        fontWeight: 700,
        height: 50,
        paddingLeft: 30,
        paddingRight: 30,
        color: Colors.darkJungleGreen,
        backgroundColor: Colors.mediumAquamarine,
        borderRadius: 25,
        '&:hover': {
            backgroundColor: Colors.moss,
        },
        '&:active': {
            backgroundColor: '#4AAF7F',
        },
        '&:focus': {
            backgroundColor: Colors.mediumAquamarine,
            border: '1px solid white',
            paddingRight: 29,
            paddingLeft: 29,
            color: 'black',
        },
        '&:disabled': {
            background: 'none',
            border: `2px solid ${Colors.cadetGrey}`,
            color: Colors.cadetGrey,
        },
    },
    // we have to lift the label above the ripple for accessability
    greenButtonLabel: {
        zIndex: 1,
    },
    // https://v3.material-ui.com/api/touch-ripple/
    greenButtonRippleChildPulsate: {
        backgroundColor: 'white',
    },
    greenBorderBtn: {
        height: 40,
        paddingLeft: 15,
        paddingRight: 15,
        border: `1px solid ${Colors.mediumAquamarine}`,
        borderRadius: 25,
        fontWeight: theme.typography.fontWeightRegular,
    },
    disabled: {},
    redButton: {
        textTransform: 'none',
        height: 50,
        minWidth: 168,
        paddingLeft: 15,
        paddingRight: 15,
        color: Colors.darkJungleGreen,
        backgroundColor: Colors.blush,
        borderRadius: 25,
        '&:hover': {
            backgroundColor: Colors.raspberryRose,
        },
        /*
        '&:active': {
            backgroundColor: '#4AAF7F',
        },
        '&:focus': {
            backgroundColor: 'rgba(91,206,151,0.5)',
            border: `1px solid ${Colors.mediumAquamarine}`,
        },
        */
    },
    greyButton: {
        textTransform: 'none',
        height: 50,
        // minWidth: 168,
        paddingLeft: 30,
        paddingRight: 30,
        color: Colors.darkJungleGreen,
        backgroundColor: Colors.gainsboro,
        borderRadius: 25,
        '&:hover': {
            backgroundColor: Colors.platinum,
        },
        /*
        '&:active': {
            backgroundColor: '#4AAF7F',
        },
        '&:focus': {
            backgroundColor: 'rgba(91,206,151,0.5)',
            border: `1px solid ${Colors.mediumAquamarine}`,
        },
        */
    },
    // we have to lift the label above the ripple for accessability
    whiteButtonLabel: {
        zIndex: 1,
    },
    whiteButton: {
        textTransform: 'none',
        height: 50,
        fontFamily: 'Inter, sans-serif',
        fontWeight: theme.typography.fontWeightMedium,
        paddingRight: 32,
        paddingLeft: 32,
        color: Colors.charcoal,
        backgroundColor: 'rgba(255,255,255,0.75)',
        border: `2px solid ${Colors.lightGrey}`,
        borderRadius: 25,
        marginBottom: 15,
        marginRight: 10,
        marginLeft: 0,
        '&:hover': {
            border: `2px solid ${Colors.mediumAquamarine}`,
            backgroundColor: 'white',
        },
        '&:focus': {
            border: `2px solid ${Colors.mediumAquamarine}`,
        },
    },
    whiteButtonSelected: {
        fontWeight: theme.typography.fontWeightBold,
        border: `2px solid ${Colors.mediumAquamarine}`,
        backgroundColor: Colors.mediumAquamarine,
        '&:hover': {
            backgroundColor: Colors.mediumAquamarine,
        },
    },
    whiteButtonRippleChildPulsate: {
        backgroundColor: Colors.moss,
    },
    primaryLink: {
        borderRadius: 0,
        padding: 15,
        marginLeft: 15,
        marginRight: 15,
        textTransform: 'capitalize',
        color: Colors.cadetGrey,
        fontWeight: 600,
        '&:hover': {
            color: 'white',
        },
        '&:active': {
            color: Colors.gainsboro,
        },
        '&:focus': {
            borderRadius: 25,
            border: '1px solid #92A7BA',
        },
    },
    primaryLinkActive: {
        color: 'white',
    },
});

export default styles;
