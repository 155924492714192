import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CombineStyles from '../../../../../utils/combine-styles';
import Styles from './select-styles';
import InputStyles from './input-styles';

// TODO: check that this will work with mobile browsers as expected
const SelectMMDDYYYY = ({
    classes,
    questionProps,
    manageFormInputs,
}) => {
    const {
        id,
        userInput,
        label,
    } = questionProps;

    const Months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const Years = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        let startYear = 1960;
        while (startYear < currentYear) {
            years.push(startYear += 1);
        }

        return years;
    };

    const Days = () => {
        const endDay = 31;
        const days = [];
        let startDay = 1;
        while (startDay < endDay) {
            days.push(startDay += 1);
        }
        return days;
    };

    const [date, setDate] = useState({
        day: '',
        month: '',
        year: '',
    });

    useEffect(() => {
        setDate(userInput);
    }, [userInput]);

    const handleChange = name => (event) => {
        const dateValue = { ...date, [name]: event.target.value };
        setDate(dateValue);
        manageFormInputs({ id, userInput: dateValue });
    };

    return (

        <FormGroup component="fieldset" className={classes.questionInputWrapper} style={{ flexDirection: 'row' }}>
            <Select
                // autowidth // used to contrain the width of the popover
                displayEmpty
                variant="outlined"
                value={userInput.month}
                onChange={handleChange('month')}
                className={classes.selectWrapper}
                classes={{
                    root: classes.selectRoot,
                }}
                inputProps={{ 'aria-label': label }}
                style={{ width: 180 }}
            >
                <MenuItem value="" disabled>Month</MenuItem>
                {Months.map(month => <MenuItem key={month} value={month} className={classes.menuItem}>{month}</MenuItem>)}
            </Select>

            <Select
                displayEmpty
                variant="outlined"
                value={userInput.day}
                onChange={handleChange('day')}
                className={classes.selectWrapper}
                classes={{
                    root: classes.selectRoot,
                }}
                style={{ width: 75 }}
            >
                <MenuItem value="" disabled>Day</MenuItem>
                {Days().map(day => <MenuItem key={day} value={day} className={classes.menuItem}>{day}</MenuItem>)}
            </Select>

            <Select
                displayEmpty
                variant="outlined"
                value={userInput.year}
                onChange={handleChange('year')}
                className={classes.selectWrapper}
                classes={{
                    root: classes.selectRoot,
                }}
                style={{ width: 130 }}
            >
                <MenuItem value="" disabled>Year</MenuItem>
                {Years().map(year => <MenuItem key={year} value={year} className={classes.menuItem}>{year}</MenuItem>)}
            </Select>
        </FormGroup>
    );
};

SelectMMDDYYYY.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        id: PropTypes.number.isRequired,
        userInput: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
SelectMMDDYYYY.defaultProps = {
    questionProps: {},
};

const combinedStyles = CombineStyles(Styles, InputStyles);
export default withStyles(combinedStyles)(SelectMMDDYYYY);
