import Colors from '../../../../../styles/colors';

const styles = theme => ({
    // used across question components
    requiredAsterisk: {
        color: Colors.darkRed,
    },
    error: {
        color: Colors.darkRed,
        paddingTop: 15,
    },
    questionTitle: {
        color: Colors.darkJungleGreen,
        paddingBottom: 10,
    },
    questionDescription: {
        color: Colors.darkElectricBlue,
        fontWeight: theme.typography.fontWeightRegular,
        paddingBottom: 10,
    },
    questionInputWrapper: {
        paddingTop: 10,
    },
    // used in question flow page
    questionWrapper: {
        padding: 30,
        borderRadius: 4,
        backgroundColor: 'white',
        margin: '0 auto',
        marginBottom: 50,
        border: '1px solid white',
        maxWidth: 720,
    },
    caseWrapper: {
        marginBottom: 0,
        borderRadius: 0,
    },
    caseTop: {
        borderRadius: '4px 4px 0 0',
    },
    caseBottom: {
        marginBottom: 50,
        borderRadius: '0 0 4px 4px',
    },
    sectionWrapper: {
        borderColor: Colors.lightGrey,
        backgroundColor: Colors.lightGrey,
        paddingBottom: 15,
    },
    htmlWrapper: {
        backgroundColor: 'rgba(254,181,22,0.2)', // darkTangerine varient
        border: '1px solid rgba(254,181,22,0.5)',
    },
    questionWrapperError: {
        border: `1px solid ${Colors.blush}`,
    },
    toolTipWrapper: {
        maxWidth: 720,
        margin: '0 auto',
        backgroundColor: Colors.topaz,
        padding: 30,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.1)',
        borderRadius: 5,
        position: 'relative',
        marginTop: -50,
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '100%',
            right: 25,
            borderTop: `20px solid ${Colors.topaz}`,
            borderTopColor: Colors.topaz,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
        },
    },
    toolTipIcon: {
        fill: Colors.cadetGrey,
        color: Colors.cadetGrey,
    },
});

export default styles;
