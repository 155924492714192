import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

import useDebounce from '../../../../../utils/use-debounce';

import CombineStyles from '../../../../../utils/combine-styles';
import Styles from './text-field-styles';
import InputStyles from './input-styles';

// TODO: check that this will work with mobile browsers as expected
const TextFieldComponent = ({
    classes,
    questionProps,
    manageFormInputs,
    multiline,
}) => {
    const {
        id,
        userInput,
        inputMask,
        label,
    } = questionProps;

    const [inputValue, setInputValue] = useState(userInput);
    const debouncedInputValue = useDebounce(inputValue, 500, true);

    useEffect(() => {
        setInputValue(userInput);
    }, [userInput]);

    useEffect(() => {
        manageFormInputs({ id, userInput: debouncedInputValue });
    },
    [debouncedInputValue]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <FormGroup component="fieldset" className={classes.questionInputWrapper}>
            <TextField
                className={classes.textField}
                placeholder={inputMask}
                variant="outlined"
                value={inputValue}
                onChange={handleChange}
                multiline={multiline}
                rows={4}
                InputProps={{
                    classes: {
                        root: classes.textField,
                    },
                    inputProps: {
                        'aria-label': label,
                    },
                }}
            />
        </FormGroup>
    );
};

TextFieldComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        id: PropTypes.number.isRequired,
        userInput: PropTypes.string.isRequired,
        inputMask: PropTypes.string,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
    multiline: PropTypes.bool.isRequired,
};
TextFieldComponent.defaultProps = {
    questionProps: {
        inputMask: '',
    },
};

const combinedStyles = CombineStyles(Styles, InputStyles);
export default withStyles(combinedStyles)(TextFieldComponent);
