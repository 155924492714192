import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';

import Colors from '../../../../../styles/colors';

const PickerTheme = theme => createMuiTheme({
    ...theme,
    overrides: {
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: Colors.darkElectricBlue,
            },
        },
    },
});

// TODO: check that this will work with mobile browsers as expected
const DatePicker = ({
    questionProps,
    manageFormInputs,
}) => {
    const {
        id,
        userInput,
        inputMask,
        label,
    } = questionProps;

    const handleDateChange = (date) => {
        if (date === null) { // produced when field is empty
            manageFormInputs({ id, userInput: '' });
        } else {
            manageFormInputs({ id, userInput: Moment(date).format() });
        }
    };

    const [formInput, setFormInput] = useState(null);
    const [dateRequestedByError, setDateRequestedByError] = useState(false);
    useEffect(() => {
        if (userInput === '') {
            setFormInput(null);
        } else {
            setFormInput(userInput);
        }
    }, [userInput]);

    return (
        <ThemeProvider theme={PickerTheme}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                label={inputMask}
                emptyLabel=""
                value={formInput}
                onChange={handleDateChange}
                helperText={(dateRequestedByError && userInput !== '') ? <span style={{ marginLeft: -10 }}>Invalid Date. MM/DD/YYYY format required.</span> : ''}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                rightArrowButtonProps={{
                    'aria-label': 'next month',
                }}
                leftArrowButtonProps={{
                    'aria-label': 'previous month',
                }}
                onError={(errResponse) => {
                    if (errResponse !== '') {
                        setDateRequestedByError(true);
                    } else {
                        setDateRequestedByError(false);
                    }
                }}
                onAccept={() => {
                    setDateRequestedByError(false);
                }}
                inputProps={{
                    'aria-label': label,
                }}
            />
        </ThemeProvider>
    );
};

DatePicker.propTypes = {
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        id: PropTypes.number.isRequired,
        userInput: PropTypes.string.isRequired,
        inputMask: PropTypes.string,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
DatePicker.defaultProps = {
    questionProps: {
        inputMask: '',
    },
};

export default DatePicker;
