import Colors from '../../../styles/colors';
import BackGroundImg from './images/footer-graphic-2x.png';

const styles = () => ({
    wrapperIntake: {
        color: 'white',
        backgroundColor: Colors.darkJungleGreen,
        borderBottom: '1px solid black',
        height: 80,
        position: 'fixed',
        bottom: 0,
    },
    wrapper: {
        color: 'white',
        backgroundColor: Colors.darkJungleGreen,
        borderBottom: '1px solid black',
        height: 80,
        backgroundImage: `url(${BackGroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 180,
        backgroundPosition: '95%',
        position: 'fixed',
        bottom: 0,
        '@media (max-width: 768px)': {
            backgroundImage: 'none',
        },
    },
    donateLink: {
        textDecoration: 'underline',
        textDecorationColor: '#17C28F',
        color: 'white',
        padding: 10,
        '&:focus': {
            borderRadius: 25,
            border: '1px solid #92A7BA',
        },
    },
    copyRightText: {
        fontSize: 12,
        color: Colors.cadetGrey,
    },
});

export default styles;
