import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Checkbox from '@material-ui/core/Checkbox';

import CountySelect from './styledComponents/county-select-v2';

import CombineStyles from '../../utils/combine-styles';
import InputStyles from '../../styles/inputs';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';

import Validation from '../../utils/validation-schema-signup';

import Logo from '../../images/myPadilla-logo-2x.png';
import SignupGraphic from '../../images/myPadilla-graphic-signup-2x.png';

import { SignUp, SignUpBegin } from '../../actions/auth';

const SignUpForm = ({ classes }) => {
    const signUpError = useSelector(state => state.auth.signUp.error);
    const signUpErrorMsg = useSelector(state => state.auth.signUp.errorMsg);
    const signUpCompleted = useSelector(state => state.auth.signUp.completed);

    const [termsApproved, setTermsApproved] = useState(false);

    const counties = useSelector(state => state.auth.counties);
    const dispatch = useDispatch();

    const history = useHistory();

    const [errorAPI, setErrorAPI] = useState(null);
    const [errorMsgAPI, setErrorMsgAPI] = useState(null);
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        firm: '',
        phoneNumber: '',
        practiceCounties: [],
        practiceType: '',
    });
    const [errors, setErrors] = useState({});
    const [validationActive, setValidationActive] = useState(false);

    // set label width for components where input label is a child
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        document.title = 'myPadilla: Signup';
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleTOSChange = (event) => {
        setTermsApproved(event.target.checked);
    };

    const validate = () => {
        setValidationActive(true);
        const validationError = Validation.validate(
            {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
                firm: values.firm,
                phoneNumber: values.phoneNumber,
                practiceCounties: values.practiceCounties,
                practiceType: values.practiceType,
                termsApproved,
            },
            { abortEarly: false },
        ).error;

        if (validationError) {
            const currentErrors = {};
            validationError.details.forEach((detail) => {
                currentErrors[detail.context.key] = detail.type;
            });

            setErrors(currentErrors);
        } else {
            setErrors({});
        }
        return validationError;
    };

    useEffect(() => {
        setErrorAPI(signUpError);
        if (signUpError) {
            setErrorMsgAPI(signUpErrorMsg);
        }
    }, [signUpError]);

    useEffect(() => {
        let timer = null;

        if (signUpCompleted && !signUpError) {
            timer = setTimeout(() => {
                // reset controlling vars
                dispatch(SignUpBegin());
                history.push('/login/signup/success');
            }, 100);
        }
        return () => clearTimeout(timer);
    }, [signUpCompleted, signUpError]);

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.firm,
        values.phoneNumber,
        values.practiceCounties,
        values.practiceType,
        termsApproved,
    ]);

    const handleChange = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCountyChange = (selectedCounties) => {
        setValues({ ...values, practiceCounties: selectedCounties });
    };

    const handleSubmit = () => {
        if (!validate()) {
            const groomedValues = { ...values };
            if (groomedValues.phoneNumber === '') delete groomedValues.phoneNumber;
            dispatch(SignUp(groomedValues));
        }
    };

    return (
        <div className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 40 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196, paddingBottom: 24 }} />
                </div>
            </div>
            {errorAPI ? (<Typography variant="body1" className={classes.errorMessage} style={{ paddingBottom: 30 }}>{errorMsgAPI}</Typography>) : null}
            <div className="row" style={{ flexWrap: 'nowrap' }}>
                <div className={`col text-center ${classes.formWrapper}`}>
                    <Typography variant="h4" component="h1" style={{ paddingBottom: 14 }}>Sign Up</Typography>
                    <div className="row">
                        <div className="col">
                            <div className={classes.inputWrapper}>
                                <TextField
                                    label="First Name"
                                    variant="outlined"
                                    type="text"
                                    autoComplete="given-name"
                                    value={values.firstName}
                                    error={errors.firstName !== undefined}
                                    onChange={handleChange('firstName')}
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textInputLabelRoot,
                                            focused: classes.textInputLabelFocused,
                                            error: classes.textInputLabelError,
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.textInput,
                                            error: classes.textInputError,
                                        },
                                        inputProps: {
                                            'aria-label': 'First Name',
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.errorMessage} role="status" aria-live="polite">
                                {errors.firstName ? 'Please enter a first name' : ''}
                            </div>
                        </div>
                        <div className="col">
                            <div className={classes.inputWrapper}>
                                <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    type="text"
                                    autoComplete="family-name"
                                    value={values.lastName}
                                    error={errors.lastName !== undefined}
                                    onChange={handleChange('lastName')}
                                    fullWidth
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textInputLabelRoot,
                                            focused: classes.textInputLabelFocused,
                                            error: classes.textInputLabelError,
                                        },
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.textInput,
                                            error: classes.textInputError,
                                        },
                                        inputProps: {
                                            'aria-label': 'Last Name',
                                        },
                                    }}
                                />
                            </div>
                            <div className={classes.errorMessage} role="status" aria-live="polite">
                                {errors.lastName ? 'Please enter a last name' : ''}
                            </div>
                        </div>
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            autoComplete="on"
                            value={values.email}
                            error={errors.email !== undefined}
                            onChange={handleChange('email')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Email Address',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.email ? 'Please enter a valid email address' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            aria-label="Password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            autoComplete="current-password"
                            value={values.password}
                            error={errors.password !== undefined}
                            fullWidth
                            onChange={handleChange('password')}
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Password',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.password ? 'Please enter a password of 8+ characters' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Firm / Organization"
                            variant="outlined"
                            type="text"
                            value={values.firm}
                            error={errors.firm !== undefined}
                            onChange={handleChange('firm')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Firm Name',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.firm ? 'Please enter a Firm / Organization' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Phone Number (optional)"
                            variant="outlined"
                            type="tel"
                            autoComplete="on"
                            value={values.phoneNumber}
                            error={errors.phoneNumber !== undefined}
                            onChange={handleChange('phoneNumber')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Phone Number',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.phoneNumber ? 'Please enter a valid phone number' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <CountySelect counties={counties} handleCountyChange={handleCountyChange} error={errors.practiceCounties !== undefined} />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.practiceCounties ? 'Please select 1 or more counties' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <FormControl
                            variant="outlined"
                            error={errors.practiceType !== undefined}
                            classes={{
                                root: classes.selectWrapper,
                            }}
                        >
                            <InputLabel
                                htmlFor="practice-type"
                                id="practice-type"
                                ref={inputLabel}
                                style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}
                                classes={{
                                    root: classes.inputLabelRoot,
                                    focused: classes.inputLabelFocused,
                                    error: classes.inputLabelError,
                                    shrink: classes.inputLabelShrink,
                                }}
                            >
                                Type of Practice
                            </InputLabel>
                            <Select
                                labelId="practice-type"
                                style={{ maxWidth: 332 }}
                                value={values.practiceType}
                                onChange={handleChange('practiceType')}
                                fullWidth
                                input={(
                                    <OutlinedInput
                                        labelWidth={labelWidth}
                                        classes={{
                                            root: classes.selectMenuRoot,
                                            error: classes.selectMenuError,
                                        }}
                                    />
                                )}
                            >
                                <MenuItem className={classes.selectMenuTitle} style={{ backgroundColor: '#FFF' }}>Criminal</MenuItem>
                                <MenuItem value="Public defender" className={classes.selectMenuItem}>Public defender</MenuItem>
                                <MenuItem value="Private attorney who accepts court appointments" className={classes.selectMenuItem}>
                                    Private attorney who accepts court appointments
                                </MenuItem>
                                <MenuItem value="Private attorney who does NOT accept court appointments" className={classes.selectMenuItem}>
                                    Private attorney who does NOT accept court appointments
                                </MenuItem>
                                <MenuItem className={classes.selectMenuTitle} style={{ backgroundColor: '#FFF' }}>Immigration</MenuItem>
                                <MenuItem value="Non-profit" className={classes.selectMenuItem}>Non-profit</MenuItem>
                                <MenuItem value="Private practice" className={classes.selectMenuItem}>Private practice</MenuItem>
                                <MenuItem value="Other" className={classes.selectMenuItem}>Other</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.practiceType ? 'Please enter a valid type of practice' : ''}
                    </div>
                    <div className="row no-gutters" style={{ paddingTop: 15 }}>
                        <div className="col text-left">
                            <Typography variant="body1">
                                You must carefully review all&nbsp;
                                <a
                                    href="/terms"
                                    target="_blank"
                                    className={classes.tosLink}
                                >
                                    <u>Terms & Conditions</u>
                                </a>
                                &nbsp;before clicking &quot;I agree.&quot; By clicking &quot;I agree&quot; you are entering into a binding agreement and agreeing to be fully bound by the Terms & Conditions.
                            </Typography>
                        </div>
                        <div className="col-auto">
                            <Checkbox
                                checked={termsApproved}
                                onChange={handleTOSChange}
                                color="default"
                                classes={{
                                    root: classes.tosCheckbox,
                                    checked: classes.tosCheckboxChecked,
                                }}
                                inputProps={{ 'aria-label': 'Approve Terms of Service' }}
                            />
                            <div className={classes.errorMessage} role="status" aria-live="polite">
                                {errors.termsApproved ? 'You must approve the Terms of Service to sign up.' : ''}
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: 20, marginBottom: 15 }}>
                        <div className="col" style={{ textAlign: 'right' }}>
                            <button
                                type="button"
                                className={classes.greenButton}
                                onClick={handleSubmit}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`col d-none d-md-flex ${classes.leftSideWrapper}`}>
                    <div className="row">
                        <div className="col" style={{ textAlign: 'right' }}>
                            <img src={SignupGraphic} style={{ height: '275px' }} alt="" />
                        </div>
                    </div>
                    <div className="row align-items-center" style={{ height: '100%', padding: '50px 43px 0px 47px' }}>
                        <div className="col" style={{ padding: 0 }}>
                            <Typography style={{
                                fontWeight: 600,
                                color: '#FFF',
                                fontSize: 20,
                                lineHeight: '24px',
                                paddingBottom: '30px',
                            }}
                            >
                                &ldquo;myPadilla gives us sound, professional, expert immigration advice so our clients can be fully informed when making choices that will affect them and their families.&rdquo;
                            </Typography>
                            <Typography style={{
                                fontWeight: 500,
                                color: '#FFF',
                                fontSize: '14px',
                                lineHeight: '17px',
                            }}
                            >
                                Far West Texas
                                <br />
                                Regional Public Defender
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ paddingTop: 37, alignItems: 'center' }}>
                <div className="col-auto" style={{ paddingRight: 0 }}>
                    <Typography variant="h3" component="h2" className={classes.needAccountLink}>Already have an account?</Typography>
                </div>
                <div
                    className="col"
                    style={{
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingLeft: 6,
                        paddingTop: 1,
                    }}
                >
                    <Link to="./" className={classes.textLink}>
                        Log in
                    </Link>
                </div>
            </div>
        </div>
    );
};

SignUpForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, InputStyles, ButtonStyles);

export default withStyles(combinedStyles)(SignUpForm);
