import Colors from '../../../styles/colors';

const styles = theme => ({
    wrapper: {
        paddingTop: 60,
        paddingBottom: 60,
    },
    tableHeader: {
        paddingLeft: 15,
        paddingRight: 15,
        height: 60,
        borderRadius: '4px 4px 0 0',
        backgroundColor: Colors.lightGrey,
    },
    tableRow: {
        borderTop: '1px solid #E3E8ED',
        paddingLeft: 15,
        paddingRight: 15,
        height: 70,
        backgroundColor: 'white',
        '&:last-of-type': {
            borderRadius: '0 0 40px 40px',
        },
    },
    tableRowClientName: {
        fontSize: 15,
        fontWeight: 700,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tableRowCauseNumber: {
        color: Colors.darkElectricBlue,
    },
    tableRowDate: {
        color: Colors.darkElectricBlue,
    },
    statusTextDraft: {
        color: Colors.darkElectricBlue,
    },
    tableRowButton: {
        height: 30,
        backgroundColor: Colors.antiFlashWhite,
        color: Colors.darkJungleGreen,
        fontSize: 13,
        fontWeight: 400,
        borderRadius: 3,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 1,
        display: 'inline-flex',
        '&:hover, &:focus': {
            backgroundColor: Colors.lightGrey,
        },
    },
    tableRowExpandIcon: {
        width: 15,
        height: 'auto',
    },
    tableRowButtonArrow: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:hover, &:focus': {
            backgroundColor: Colors.lightGrey,
        },
    },
    blockTop: {
        height: 60,
        borderRadius: '4px 4px 0 0',
        backgroundColor: Colors.mediumAquamarine,
    },
    blockTopOrange: {
        backgroundColor: Colors.darkTangerine,
    },
    blockBottom: {
        borderRadius: '0 0 4px 4px',
        backgroundColor: 'white',
        padding: 40,
        minHeight: 363,
    },
    checkIcon: {
        color: Colors.mediumAquamarine,
        fill: Colors.mediumAquamarine,
    },
    howItWorksTextWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 10,
    },
    howItWorksText: {
        paddingLeft: 10,
        display: 'inline-block',
        fontSize: 14,
        color: Colors.darkElectricBlue,
    },
    blockTopIcon: {
        height: 30,
    },
    blockTopTitle: {
        fontSize: 17,
        fontWeight: 'bold',
        color: Colors.darkJungleGreen,
        display: 'inline',
        paddingLeft: 10,
    },
    statusIcon: {
        marginRight: 5,
        display: 'inline-flex',
        backgroundColor: Colors.cadetGrey,
        height: 12,
        width: 12,
        borderRadius: 12,
    },
    statusIconDraft: {
        backgroundColor: Colors.cadetGrey,
    },
    statusIconReview: {
        backgroundColor: Colors.darkTangerine,
    },
    statusIconCompleted: {
        backgroundColor: '#17C28F',
    },
    viewAllBtn: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 25,
        paddingLeft: 10,
        '&:focus': {
            marginRight: -2,
            border: `2px solid ${Colors.darkJungleGreen}`,
        },
    },
    viewAllIconWrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        backgroundColor: 'white',
        borderRadius: 40,
        marginLeft: 10,
    },
    viewAllIcon: {
        color: '#17C28F',
        fill: '#17C28F',
    },
    popoverWrapper: {
        borderRadius: 0,
        width: 160,
        paddingTop: 10,
        paddingBottom: 10,
        '& button': {
            display: 'block',
        },
    },
    popoverButton: {
        width: '100%',
        padding: 10,
        paddingLeft: 30,
        textAlign: 'left',
        '&:focus, &:hover': {
            backgroundColor: Colors.gainsboro,
        },
    },
    noInternetWrapper: {
        borderRadius: 4,
        backgroundColor: 'white',
        height: 80,
        marginLeft: -15,
        marginRight: -15,
        marginTop: 40,
    },
    noInternetLeftCol: {
        backgroundColor: Colors.blush,
        borderRadius: '4px 0 0 4px',
        maxWidth: 80,
        height: 80,
    },
    noInternetTitle: {
        color: Colors.darkJungleGreen,
        fontWeight: theme.typography.fontWeightBold,
        display: 'inline-block',
        marginRight: 10,
    },
});
export default styles;
