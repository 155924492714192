import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ResetPass } from '../../actions/auth';

import CombineStyles from '../../utils/combine-styles';
import InputStyles from '../../styles/inputs';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import Validation from '../../utils/validation-schema-password-reset';

import Logo from '../../images/myPadilla-logo-2x.png';
import LoginGraphic from '../../images/myPadilla-graphic-login-2x.png';

const ResetPassword = ({ classes }) => {
    const resetpassError = useSelector(state => state.auth.resetPass.error);
    const resetpassErrorMsg = useSelector(state => state.auth.resetPass.errorMsg);
    const resetpassCompleted = useSelector(state => state.auth.resetPass.completed);
    const dispatch = useDispatch();

    const history = useHistory();

    const { resetToken } = useParams();
    const [values, setValues] = React.useState({ password: '', passwordConfirm: '', email: '' });
    const [errors, setErrors] = useState({});
    const [validationActive, setValidationActive] = useState(false);

    useEffect(() => {
        document.title = 'myPadilla: Reset Password';
    }, []);

    useEffect(() => {
        setValues({ ...values, resetToken });
    }, [resetToken]);

    useEffect(() => {
        let timer = null;
        if (resetpassCompleted && !resetpassError) {
            timer = setTimeout(() => {
                history.push('/login');
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [resetpassCompleted, resetpassError]);

    const validate = () => {
        setValidationActive(true);

        const validationError = Validation.validate({ email: values.email, password: values.password, passwordConfirm: values.passwordConfirm }, { abortEarly: false }).error;

        if (validationError) {
            const currentErrors = {};
            validationError.details.forEach((detail) => {
                currentErrors[detail.context.key] = detail.type;
            });

            setErrors(currentErrors);
        } else {
            setErrors({});
        }
        return validationError;
    };

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [
        values.email,
        values.password,
        values.passwordConfirm,
    ]);

    const handleSubmit = () => {
        if (!validate()) {
            // no error
            // let's make an API Call
            const currentFormValue = {
                email: values.email,
                resetToken,
                newPassword: values.password,
            };

            dispatch(ResetPass(currentFormValue));
        }
    };

    const handleChange = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <main className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 40 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196, paddingBottom: 24 }} />
                </div>
            </div>
            <div role="status" aria-live="polite">
                {resetpassError ? (<Typography variant="body1" className={classes.errorMessage} style={{ paddingBottom: 30 }}>{resetpassErrorMsg}</Typography>) : null}
                {(resetpassCompleted && !resetpassError) && (<Typography variant="body1" className={classes.successMessage} style={{ paddingBottom: 30 }}>Success: Your password has successfully been updated.</Typography>)}
            </div>
            <div className="row" style={{ flexWrap: 'nowrap' }}>
                <div className={`col text-center ${classes.formWrapper}`}>
                    <Typography variant="h4" component="h1" style={{ paddingBottom: 14 }}>Reset your password</Typography>
                    <div className={classes.inputWrapper}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            autoComplete="on"
                            value={values.email}
                            error={errors.email !== undefined}
                            onChange={handleChange('email')}
                            fullWidth
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Email',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.email ? 'Please enter your email address' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            aria-label="Password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            autoComplete="off"
                            fullWidth
                            value={values.password}
                            error={errors.password !== undefined}
                            onChange={handleChange('password')}
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Password',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.password ? 'Please enter a new password (8+ characters required)' : ''}
                    </div>
                    <div className={classes.inputWrapper}>
                        <TextField
                            aria-label="Password"
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            autoComplete="off"
                            fullWidth
                            value={values.passwordConfirm}
                            error={errors.passwordConfirm !== undefined}
                            onChange={handleChange('passwordConfirm')}
                            InputLabelProps={{
                                classes: {
                                    root: classes.textInputLabelRoot,
                                    focused: classes.textInputLabelFocused,
                                    error: classes.textInputLabelError,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.textInput,
                                    notchedOutline: classes.notchedOutline,
                                    error: classes.textInputError,
                                },
                                inputProps: {
                                    'aria-label': 'Confirm Password',
                                },
                            }}
                        />
                    </div>
                    <div className={classes.errorMessage} role="status" aria-live="polite">
                        {errors.passwordConfirm ? 'Your passwords do not match' : ''}
                    </div>
                    <div className={`row no-gutters ${classes.forgotPasswordWrapper}`}>
                        <div className="col" style={{ textAlign: 'right' }}>
                            <button
                                type="button"
                                className={classes.greenButton}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`col d-none d-md-flex justify-content-end align-items-end ${classes.leftSideWrapper}`}>
                    <img src={LoginGraphic} className={classes.loginGraphic} alt="" />
                </div>
            </div>
            <div className="row" style={{ paddingTop: 37, alignItems: 'center' }}>
                <div className="col-auto" style={{ paddingRight: 0 }}>
                    <Typography variant="h3" component="h2" className={classes.needAccountLink}>Take me back to</Typography>
                </div>
                <div
                    className="col"
                    style={{
                        fontWeight: 600,
                        textAlign: 'left',
                        paddingLeft: 6,
                        paddingTop: 1,
                    }}
                >
                    <Link to="/login" className={classes.textLink}>
                        Log in
                    </Link>
                </div>
            </div>
        </main>
    );
};

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, InputStyles, ButtonStyles);

export default withStyles(combinedStyles)(ResetPassword);
