import { orderBy } from 'lodash';

const IntakeTypes = require('../action-types/intakes');

const internals = {
    initial: () => ({
        intakeSubmitted: false, // used to flag success banner
        createIntake: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        updateIntake: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        deleteEntry: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        getIntakes: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        intakes: [],
        getIntakeActive: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        defaultAnswerSet: {}, // used to create answer set for new intakes
    }),
};

// NOTE: we have to do some weird things with indexes to create the data set
// We also have to do some funky things when dynamically referencing indexed elements of this data set in the app
const createDefaultAnswers = (values) => {
    const answerSet = { pages: [] };
    for (let i = 0; i < values.pageTitles.length; i += 1) {
        const pageQuestions = values.questions.filter(question => question.pageNumber === (i + 1));

        for (let q = 0; q < pageQuestions.length; q += 1) {
            if (pageQuestions[q].type === 'checkbox') {
                pageQuestions[q].userInput = [];
            } else if (pageQuestions[q].type === 'fileupload' || pageQuestions[q].type === 'number' || pageQuestions[q].type === 'radio' || pageQuestions[q].type === 'textarea' || pageQuestions[q].type === 'text') {
                pageQuestions[q].userInput = '';
            } else if (pageQuestions[q].type === 'datepicker') {
                pageQuestions[q].userInput = '';
            } else if (pageQuestions[q].type === 'datedropdown' || pageQuestions[q].type === 'datefield') {
                pageQuestions[q].userInput = { month: '', day: '', year: '' };
            } else if (pageQuestions[q].type === 'select') {
                pageQuestions[q].userInput = { text: '' };
            } else {
                pageQuestions[q].userInput = null;
            }
            delete pageQuestions[q].size;
            delete pageQuestions[q].visibility;
            delete pageQuestions[q].inputs;
        }

        const sortedArray = orderBy(pageQuestions, question => question.position);
        answerSet.pages.push({
            pageNumber: i,
            pageTitle: values.pageTitles[i],
            status: 'not started',
            questions: sortedArray,
        });
    }
    return answerSet;
};

const IntakeReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case IntakeTypes.INTAKE_CREATE_BEGIN:
    case IntakeTypes.INTAKE_CREATE_ERROR:
        return {
            ...state,
            createIntake: {
                ...payload,
            },
        };

    case IntakeTypes.INTAKE_CREATE_SUCCESS:
        return {
            ...state,
            createIntake: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };

    case IntakeTypes.INTAKE_UPDATE_BEGIN:
    case IntakeTypes.INTAKE_UPDATE_ERROR:
        return {
            ...state,
            intakeSubmitted: false,
            updateIntake: {
                ...payload,
            },
        };

    case IntakeTypes.INTAKE_UPDATE_SUCCESS:
        return {
            ...state,
            intakeSubmitted: payload.intakeSubmitted,
            updateIntake: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };

    case IntakeTypes.GET_INTAKES_BEGIN:
    case IntakeTypes.GET_INTAKES_ERROR:
        return {
            ...state,
            getIntakes: {
                ...payload,
            },
        };
    case IntakeTypes.GET_INTAKES_SUCCESS: {
        const sortedArray = orderBy(payload, ['createdAt'], ['desc']);
        return {
            ...state,
            intakes: sortedArray,
            getIntakes: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };
    }

    case IntakeTypes.DELETE_ENTRY_BEGIN:
    case IntakeTypes.DELETE_ENTRY_ERROR:
    case IntakeTypes.DELETE_ENTRY_SUCCESS:
        return {
            ...state,
            deleteEntry: {
                ...payload,
            },
        };

    case IntakeTypes.GET_ACTIVE_FORM_BEGIN:
    case IntakeTypes.GET_ACTIVE_FORM_ERROR:
        return {
            ...state,
            getIntakeActive: {
                ...payload,
            },
        };
    case IntakeTypes.GET_ACTIVE_FORM_SUCCESS: {
        return {
            ...state,
            defaultAnswerSet: createDefaultAnswers(payload),
            getIntakeActive: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };
    }

    default:
        // do nothing
    }
    return state;
};


export default IntakeReducer;
