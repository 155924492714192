import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import { Logout } from '../../../actions/auth';

import Logo from './images/logo.png';

import NewFormDialog from './components/dialog-new-form';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import Styles from './styles';

const AdminHeader = ({ classes }) => {
    const dispatch = useDispatch();
    const userName = useSelector(state => state.auth.user.firstName);
    const DashboardLink = React.forwardRef((props, ref) => <NavLink activeClassName={classes.primaryLinkActive} exact to="/" {...ref} {...props} />);
    const FormsLink = React.forwardRef((props, ref) => <NavLink activeClassName={classes.primaryLinkActive} to="/all-intakes" {...ref} {...props} />);

    const LogoutFunc = () => dispatch(Logout());

    const [newFormDialogOpen, setNewFormDialogOpen] = useState(false);

    const closeNewFormDialog = useCallback(() => {
        setNewFormDialogOpen(false);
    }, []);

    return (
        <header className={`container-fluid ${classes.wrapper}`}>
            <div className="row align-items-center" style={{ width: '100%', justifyContent: 'center' }}>
                <div className="col-auto">
                    <Link to="/">
                        <img src={Logo} alt="Dashboard" className={classes.logo} />
                    </Link>
                </div>
                <div className="col text-left d-none d-lg-flex">
                    <Typography variant="body1" className={classes.welcomeText}>
                        Welcome&nbsp;
                        {userName}
                    </Typography>
                </div>
                <div className={`col-auto d-flex ${classes.navButtonsWrapper}`}>
                    <Button component={DashboardLink} className={classes.primaryLink}>
                        Dashboard
                    </Button>
                    <Button component={FormsLink} className={classes.primaryLink}>All Forms</Button>
                    <button type="button" onClick={LogoutFunc} className={classes.primaryLink}>Log Out</button>
                    <Button
                        type="button"
                        className={classes.greenButton}
                        onClick={() => setNewFormDialogOpen(true)}
                        classes={{
                            label: classes.greenButtonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.greenButtonRippleChildPulsate,
                            },
                        }}
                    >
                        Start New Intake
                    </Button>
                </div>
            </div>
            <NewFormDialog handleClose={closeNewFormDialog} open={newFormDialogOpen} />
        </header>
    );
};

AdminHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles);

export default withStyles(combinedStyles)(AdminHeader);
