import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Logo from '../../images/myPadilla-logo-2x.png';
import Styles from './styles';


const TermsAndConitions = ({ classes }) => {
    useEffect(() => {
        document.title = 'myPadilla: Terms and Conditions';
    }, []);

    return (
        <main className={`container ${classes.wrapper}`}>
            <div className="row" style={{ paddingBottom: 50 }}>
                <div className="col">
                    <img src={Logo} alt="" style={{ width: 196 }} />
                </div>
            </div>
            <div className={`row ${classes.contentWrapper}`}>
                <div className="col">
                    <Typography variant="h1" className="text-center">
                        MYPADILLA TERMS AND CONDITIONS
                    </Typography>
                    <Typography variant="h2">
                        myPadilla SERVICES
                    </Typography>
                    <Typography variant="body1">
                        myPadilla offers legal consultation to criminal defense attorneys intended to assist such attorneys in meeting their obligations under Padilla v. Kentucky, 559 U.S. 356 (2010). myPadilla&#39;s services may include: (i) providing a platform through which a criminal defense attorney can request and receive information from an immigration attorney regarding Padilla obligations and/or immigration consequences of criminal dispositions; (ii) connecting a criminal attorney directly with immigration attorney(s) for consulting services; (iii) conducting trainings for the benefit of criminal defense attorneys on immigration-related topics; and (iv) a range of other services and communications that may be offered by myPadilla related to meeting Padilla obligations, and other related immigration issues.
                    </Typography>

                    <Typography variant="h2">
                        BINDING CONTRACTUAL NATURE OF THESE TERMS AND CONDITIONS
                    </Typography>
                    <Typography variant="body1">
                        These Terms and Conditions are intended to apply to all criminal defense attorneys who utilize myPadilla&#39;s services.
                    </Typography>
                    <Typography variant="body1">
                        The attorney agreeing to these Terms and Conditions (the &quot;Contracting Attorney&quot;, alternately referred to, for purposes of the specifics terms and affirmations below, &quot;I&quot;) must thoroughly review and agree to the terms below (collectively referred to, along with the entirety of this document, as the &quot;Terms and Conditions&quot;) which are intended to govern the ongoing relationship between the Contracting Attorney and myPadilla. These Terms and Conditions will apply every time the Contracting Attorney  utilizes myPadilla&#39;s services in the future, including for each case regarding which the Contracting Attorney requests assistance from myPadilla.
                    </Typography>
                    <Typography variant="body1" style={{ textDecoration: 'underline', fontWeight: 600 }}>
                        By affirmation of these Terms and Conditions on the myPadilla platform, the Contracting Attorney is expressly agreeing to form a legally binding contract between the Contracting Attorney and myPadilla.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        Accordingly, it is essential that: (i) the Contracting Attorney carefully review the entirety of these &quot;Terms and Conditions&quot;, including the specific terms below, prior to affirming agreement to these Terms on the myPadilla platform; and (ii) the Contracting Attorney re-review these terms prior to engaging with myPadilla for any client-specific services, in order to determine whether such terms are agreeable to the relevant case and/or client to which the services relate.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        The below specific terms include a series of terms under which the Contracting Attorney is referred to as &quot;I,&quot; and by agreeing to these Terms and Conditions on the myPadilla platform, the Contracting Attorney expressly agrees to be bound by all such terms.
                    </Typography>

                    <Typography variant="h2">
                        CONTRACTING ATTORNEY STATUS
                    </Typography>
                    <Typography variant="body1">
                        I (meaning, for purposes of this section and all sections below, the Contracting Attorney) expressly represent that I am an attorney admitted to and in good standing with the State Bar of Texas, and I acknowledge that I have an ongoing duty to immediately inform myPadilla if such standing changes in any way.
                    </Typography>

                    <Typography variant="h2">
                        RELATIONSHIP WITH MYPADILLA
                    </Typography>
                    <Typography variant="body1">
                        I agree that my relationship with myPadilla shall be a consulting relationship between myself and myPadilla, and that for purposes of these &quot;Terms and Conditions,&quot; the term &quot;myPadilla&quot; includes any immigration attorney(s) and/or administrative staff, other staff, interns or associates with whom I communicate via the myPadilla platform.
                    </Typography>
                    <Typography variant="body1">
                        I agree that the only services myPadilla will provide will be short-term, limited-scope legal consultation, in order to provide information intended to assist me in meeting my obligations under&nbsp;
                        <i>Padilla.</i>
                    </Typography>
                    <Typography variant="body1">
                        I agree further that nothing in these Terms and Conditions shall be construed as creating a partnership, joint venture, or any similar relationship between myself and myPadilla.
                    </Typography>

                    <Typography variant="h2">
                        NO RELATIONSHIP BETWEEN MYPADILLA AND CONTRACTING ATTORNEY&#39;S CLIENTS
                    </Typography>
                    <Typography variant="body1">
                        I agree that, although myPadilla may provide me legal advice in response to requests I make related to specific clients of mine (and I may share information regarding such clients), there shall be no contractual relationship of any kind between my clients and myPadilla. Aside from a duty of confidentiality and privilege arising from my direct relationship with my clients on one hand, and my relationship with myPadilla on the other (as discussed elsewhere in these Terms and Conditions), myPadilla shall owe no duty of any kind to any of my clients. Without in any way limiting the foregoing, I expressly understand and agree that nothing in these Terms and Conditions, and nothing regarding my relationship with myPadilla, shall be deemed to create an attorney-client relationship between myPadilla and any of my clients.
                    </Typography>
                    <Typography variant="body1">
                        I agree that prior to submitting any client-specific information to myPadilla, I will secure consent from the relevant client to utilize myPadilla, including but not limited to consent to my being bound by these Terms and Conditions.
                    </Typography>

                    <Typography variant="h2">
                        ATTORNEY-CLIENT PRIVILEGE AND CONFIDENTIALITY
                    </Typography>
                    <Typography variant="body1">
                        I agree that I intend to utilize myPadilla for the receipt of specialized legal consultation that may assist me in the representation of certain clients, and that, accordingly, any client-specific communications or exchange of information between myself and myPadilla shall be subject to privilege, as well as to the attorney-client work product doctrine, stemming from my representation of such client, and myPadilla&#39;s role as a third-party consultant retained by me for the specific purpose of assisting with my legal representation of the client, and therefore subject to such privilege.
                    </Typography>
                    <Typography variant="body1">
                        Specifically, and without limiting the foregoing, I agree that, as described in Texas Rule of Evidence 503, all communications between myself and myPadilla (i) are intended to be, and will be, held as confidential communications, (ii) are made for the purpose of facilitating the rendition of professional legal services to my clients, (iii) are between myself and a representative (myPadilla) with whom I am contracting to assist with the rendition of professional legal services, and (iv) that no privilege held by any of my clients has been or will be waived throughout my relationship with myPadilla. I understand and agree that, except as expressly provided elsewhere in these Terms and Conditions, myPadilla will hold any client-specific information in careful confidence, unless I expressly and in writing verify that the relevant client has authorized such disclosure.
                    </Typography>

                    <Typography variant="h2">
                        PROVISION OF SERVICES
                    </Typography>
                    <Typography variant="body1">
                        I agree that I shall be responsible for providing information as requested by myPadilla, and in the manner, format, and timeframes required by myPadilla.
                    </Typography>
                    <Typography variant="body1">
                        I understand and agree that myPadilla will require me to submit information regarding individual cases via myPadilla&#39;s online platform, and that I am obligated to provide all information requested by myPadilla, in the manner and format requested by myPadilla.
                    </Typography>
                    <Typography variant="body1">
                        I agree that if I attempt to provide information or seek information outside of the myPadilla platform, or in any manner not expressly requested by myPadilla, then myPadilla shall have no obligation to respond to such inquiries.
                    </Typography>
                    <Typography variant="body1">
                        I understand that, provided that I submit inquiries properly via the myPadilla platform, (i) myPadilla will endeavor to provide responses within the later of (i) 10 days from the receipt of all information requested by myPadilla, excluding federal and state holidays; (ii) the deadline requested by me; or (iii) the date provided by myPadilla based upon the specific circumstances of my request. I understand and agree, however, that myPadilla reserves the right to decline to provide services for any reason, including but not limited to a situation where myPadilla is unable to effectively answer my inquiries within the timeframe I have requested. I further understand and agree that myPadilla does not guarantee submission of responses within the timeframes above, but in the event that service unavailability affects the above-described timeframes, myPadilla will provide notice of such service unavailability, and of the timeline in which myPadilla can provide a response, within a reasonable time frame.  I expressly agree to be notified of and bound by such changes via email.
                    </Typography>

                    <Typography variant="h2">
                        NO GUARANTEE
                    </Typography>
                    <Typography variant="body1">
                        I acknowledge and agree that (i)  immigration law is complex, and is often subject to shifting interpretations by relevant government authorities, and that there is often no clear answer on a specific issue; AND (ii) any information offered by myPadilla will be based upon applying myPadilla&#39;s knowledge of immigration law to the facts and information provided by me, and that I am responsible for providing complete and accurate information.
                    </Typography>
                    <Typography variant="body1">
                        Accordingly, I expressly acknowledge and agree that myPadilla does not make any express or implied guarantees of any kind to myself, or to my client, about the outcomes of any matters I may handle for my clients.
                    </Typography>

                    <Typography variant="h2">
                        SOLE RESPONSIBILITY FOR COMMUNICATIONS WITH CLIENT, DECISION-MAKING
                    </Typography>
                    <Typography variant="body1">
                        I acknowledge and agree that I am solely responsible for my conduct and my actions on behalf of my client, and that I must rely on my own research, investigation, professional judgment, and consultation with my clients in determining strategy and making decisions about matters I may be handling, and that, with regards to any case I discuss with myPadilla, all decisions I make, and all advice I provide to my client, are my full and sole responsibility.
                    </Typography>
                    <Typography variant="body1">
                        I agree, further, that it is my sole responsibility to communicate with my clients, to deliver and receive information and to confirm the accuracy of such information, and to fully discuss and consider all decisions related to my representation of such clients with such clients.
                    </Typography>
                    <Typography variant="body1">
                        I agree, further, not to improperly use information provided by myPadilla in a manner to unlawfully assist individuals to evade the lawful executions of immigration laws.
                    </Typography>

                    <Typography variant="h2">
                        NO OBLIGATION TO ASSIST WITH POST-CONVICTION MATTERS
                    </Typography>
                    <Typography variant="body1">
                        In the event that a writ to vacate a conviction based on ineffective assistance of counsel is brought against me in any case regarding which I have previously consulted with myPadilla - or in the event of any other legal proceeding, grievance, or similar action arising from any such case - I understand and agree that, as a matter of general policy, myPadilla will participate in such matter only as required by legal process, and that myPadilla is not obligated to assist or participate in any way in my defense. Without limiting the foregoing, I expressly acknowledge and agree that:
                    </Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                myPadilla is not obligated to assist or participate in any post-conviction matters except as required by legal process;
                            </li>
                            <li>
                                myPadilla will only maintain records as provided elsewhere in these Terms and Conditions, and that I shall be responsible for maintaining a careful documentary record regarding any of my cases; and
                            </li>
                            <li>
                                in the event that myPadilla is obligated to, or decides in its sole discretion to, participate in any post-conviction matter, I expressly and knowingly waive any conflicts of interest that might arise from such participation.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="h2">
                        TERMINATION OF RELATIONSHIP WITH MYPADILLA
                    </Typography>
                    <Typography variant="body1">
                        I agree that my relationship with myPadilla, pursuant to these Terms and Conditions, may be terminated at any time by myself or myPadilla by submission of written notice by the terminating party of intent to terminate immediately. In the event that myPadilla terminates the relationship, and that such termination is not &quot;for cause&quot; (as defined below), myPadilla will complete provision of services for any active inquiries I have submitted, and I will remain responsible for all obligations arising out of such services. In the event that I terminate the relationship, or that myPadilla&#39;s termination is &quot;for cause&quot; – meaning termination for reasons including breach by me of these Terms and Conditions, breach of any other terms (written or oral, express or implied) of any other terms or expectations of the relationship, or any other acts or omissions by me or any third party that materially impact the relationship with myPadilla and/or myPadilla&#39;s ability to provide services – myPadilla may immediately cease all work on any inquiries submitted by me.
                    </Typography>

                    <Typography variant="h2">
                        LIMITATION OF LIABILITY
                    </Typography>
                    <Typography variant="body1">
                        I agree that myPadilla will have no liability to me for any incidental, special, consequential, exemplary, punitive, or indirect damages suffered by myself or my client that relate to or arise out of myPadilla&#39;s services, or any decision, transaction or matter related to the information provided to me by myPadilla, unless such loss results entirely from myPadilla&#39;s gross negligence, willful misconduct, or bad faith.
                    </Typography>

                    <Typography variant="h2">
                        INDEMNIFICATION
                    </Typography>
                    <Typography variant="body1">
                        It being understood that myPadilla&#39;s relationship is solely with myself (and that no relationship is created between myPadilla and any third party, including any of my clients), I agree to defend, indemnify and hold harmless myPadilla against any and all claims, liabilities, losses, damages and expenses, including reasonable attorney fees and expenses, resulting from any claims by my clients, or any other third parties, or any breach by myself of any of these Terms and Conditions, or relating to or arising out of the services myPadilla provides.
                    </Typography>

                    <Typography variant="h2">
                        DATA RETENTION
                    </Typography>
                    <Typography variant="body1">
                        With regards to myPadilla&#39;s handling and retention of client-specific information which I may provide, I understand and agree to the following:
                    </Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                myPadilla shall hold identifying client-specific information in careful confidence, except that myPadilla may provide identifying information such as client name, case number, and other identifying information, to third parties (e.g., funders or grantors) with whom myPadilla has a contractual obligation, in order to allow such third parties to verify and validate the provision of services.
                            </li>
                            <li>
                                myPadilla will generally retain all identifying client-specific information provided through the myPadilla platform for the minimum time required by law (or by relevant rules of ethics or industry norms), and is authorized, by me, to promptly destroy such information following expiration of such time periods.
                            </li>
                            <li>
                                myPadilla may retain non-identifying information about my clients indefinitely, and I authorize myPadilla to use such information for any lawful purpose, including but not limited to aggregating data, statistical analysis, and grant reporting.
                            </li>
                            <li>
                                myPadilla will endeavor to secure all information provided by me using reasonably necessary means. In the event of a data breach, however, I agree to hold myPadilla harmless.
                            </li>
                            <li>
                                Prior to sharing any client-specific information with myPadilla, I will have secured consent from my client with specific regards to the terms above, and I understand that sharing my client&#39;s information with myPadilla without obtaining his/her informed consent could be a violation of Texas Rules of Disciplinary Conduct, including Rules 1.05 and 1.03.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="h2">
                        INFORMATION REGARDING CONTRACTING ATTORNEY
                    </Typography>
                    <Typography variant="body1">
                        I affirm that I have provided, and at all times in the future will provide, myPadilla with accurate information regarding (i) my status as a licensed and authorized attorney, in good standing in the state of Texas; (ii) the firm and/or organization for which I work; (iii) the fee relationship between myself and any of my clients for whom I seek consultation via myPadilla; and (iv) any and all other information requested by myPadilla.
                    </Typography>
                    <Typography variant="body1">
                        In the event that any of the information I have provided to myPadilla changes, I will promptly update myPadilla of any such change.
                    </Typography>
                    <Typography variant="body1">
                        I expressly understand and agree that if I depart my present law firm/organization, I will not be entitled to access client-specific information from myPadilla relating to clients of that law/firm organization.
                    </Typography>

                    <Typography variant="h2">
                        PAYMENT OBLIGATIONS AND OTHER BREACHES
                    </Typography>
                    <Typography variant="body1">
                        I understand and agree that I am obligated to pay fees commensurate with the fee schedule located at mypadilla.com/fees, and that such fee schedule – and specific fees, reductions or fee waivers depending on my circumstances or the location of my clients – are subject to change at any time, and that I am obligated to affirm such fee schedule prior to submitting any client information.
                    </Typography>
                    <Typography variant="body1">
                        I agree, further, that in the event I have an outstanding balance due of any fees for more than 30 days, myPadilla may cease providing information, terminate its relationship me, or pursue any and all other remedies available to myPadilla.
                    </Typography>
                    <Typography variant="body1">
                        I agree, further, that certain of my fees may be based upon my fee arrangement with my client (i.e. if the client has retained me, or if I am appointed to represent the client), or the location of my client&#39;s case (i.e. certain appointed cases in certain Texas counties may be funded by grants), and that in the event I have provided any false information regarding such arrangement, fail to update myPadilla of any material change in that arrangement, or fail to provide information and verification as required by myPadilla or any other third party (i.e. funding source), this would be a material breach of these Terms and Conditions.
                    </Typography>
                    <Typography variant="body1">
                        I agree further that, in the event of any breach by me of these Terms and Conditions, myPadilla may cease providing information, terminate its relationship, and/or pursue any and all other remedies available to myPadilla.
                    </Typography>

                    <Typography variant="h2">
                        UPDATE OF TERMS AND CONDITIONS; OTHER TERMS
                    </Typography>
                    <Typography variant="body1">
                        I agree that myPadilla may update these Terms and Conditions at any time, and for any reason, in myPadilla&#39;s sole discretion, and that my express agreement to any such updated Terms and Conditions will be required in order for myPadilla to continue providing services to me.
                    </Typography>
                    <Typography variant="body1">
                        I agree, further, that over the course of my relationship with myPadilla, I may receive and/or be asked to affirm additional terms, conditions or other documents, including but not limited to (i) terms and schedules regarding payment and fees, (ii) terms regarding specific requests for services, or (iii) terms which I will be asked to affirm upon submitting client-specific information to myPadilla. With regards to all such additional terms – whether formal or informal, whether or not expressly affirmed by me – I expressly agree that: (i) such terms shall be deemed incorporated into these Terms and Conditions as if fully set forth herein; and (ii) in the event of any conflict between such additional terms and these Terms and Conditions, these Terms and Conditions shall prevail.
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        By clicking &quot;I Agree&quot;, the Contract Attorney expressly agrees that (i) the above Terms and Conditions represent a binding contract between the Contracting Attorney and myPadilla; and (ii) the Contracting Attorney agrees to be fully bound by all such Terms and Conditions.
                    </Typography>
                </div>
            </div>
        </main>
    );
};

TermsAndConitions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(TermsAndConitions);
