import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CombineStyles from '../../../../../utils/combine-styles';
import Styles from './select-styles';
import InputStyles from './input-styles';

// TODO: check that this will work with mobile browsers as expected
const SelectComponent = ({
    classes,
    questionProps,
    manageFormInputs,
}) => {
    const {
        choices,
        id,
        userInput,
        inputMask,
        label,
    } = questionProps;

    const handleChange = (event) => {
        manageFormInputs({ id, userInput: event.target.value });
    };

    return (

        <FormGroup component="fieldset" className={classes.questionInputWrapper}>
            <InputLabel id={id.toString()} className="screen-reader-only">{label}</InputLabel>
            <Select
                // autowidth // used to contrain the width of the popover
                variant="outlined"
                value={userInput}
                onChange={handleChange}
                classes={{
                    root: classes.selectRoot,
                }}
                labelId={id.toString()}
            >
                {inputMask && <MenuItem value="" disabled>{inputMask}</MenuItem>}
                {choices.map(choice => <MenuItem key={choice.text} value={choice.value} className={classes.menuItem}>{choice.text}</MenuItem>)}
            </Select>
        </FormGroup>
    );
};

SelectComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        choices: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        userInput: PropTypes.string.isRequired,
        inputMask: PropTypes.string,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
SelectComponent.defaultProps = {
    questionProps: {
        inputMask: '',
    },
};

const combinedStyles = CombineStyles(Styles, InputStyles);
export default withStyles(combinedStyles)(SelectComponent);
