import Colors from '../../styles/colors';

const styles = () => ({
    bannerWrapper: {
        borderRadius: 3,
        height: 60,
        boxShadow: '0 2px 6px 0 rgba(92,110,129,0.3)',
        backgroundColor: 'white',
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
    },
    errorIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.blush,
        height: '100%',
        borderRadius: '3px 0 0 3px',
    },
    successIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.mediumAquamarine,
        height: '100%',
        borderRadius: '3px 0 0 3px',
    },
    icon: {
        height: 30,
        width: 'auto',
    },
});

export default styles;
