import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';

import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

// Save Dialog Dependencies
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// End Save Dialog Dependencies

import LogoFile from '../images/mypadilla-icon-2x.png';

import CombineStyles from '../../../../utils/combine-styles';
import Styles from './styles';
import ButtonStyles from '../../../../styles/buttons';
import InputsStyles from '../../../../styles/inputs';
import ClientRequiredImg from './images/client-required-2x.png';

import { UpdateIntake, GetIntakes } from '../../../../actions/intakes';

import { QuestionRecorder } from '../../../../utils/ga';

import Colors from '../../../../styles/colors';

const IntakeDashboard = ({ classes }) => {
    const { id } = useParams();

    const intakes = useSelector(state => state.intakes.intakes);
    const [currentIntake, setCurrentIntake] = useState({ answers: { pages: [] } });
    const [currentIntakeId, setCurrentIntakeId] = useState('');
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    // FINSH & SUBMIT DIALOG DATA
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dateRequestedBy, setDateRequestedBy] = useState('');
    const [submitComments, setSubmitComments] = useState('');
    const [error, setError] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        // the user can use the back button and leave a edited form without properly saving
        // so lets fetch all the forms again to make sure they are current
        // this is very much brut force attemp at solving the problem
        dispatch(GetIntakes());
        document.title = 'myPadilla: Intake - Dashboard';
    }, []);

    useEffect(() => {
        // get id from url
        setCurrentIntakeId(id);
        // no active id selected, so send user to dashboard
        if (!id) {
            history.push('/');
        } else {
            // look into the intakes and find the one we are trying to display
            // note: id comes in as a string from the param in url
            const tempIntake = _.find(intakes, form => form.id === parseInt(id, 10));
            // if we found it, load it.
            // else, if we can't find it, do something?
            if (tempIntake !== undefined) {
                setCurrentIntake(tempIntake);

                // evaulate if we can allow the user to submit their intake
                if (tempIntake.answers) {
                    if (tempIntake.answers.pages[0].status === 'complete' && tempIntake.answers.pages[1].status === 'complete' && tempIntake.answers.pages[2].status === 'complete') {
                        setReadyToSubmit(true);
                    }
                }
            }
        }
    }, [intakes, id]);

    const gotoPage = (page) => {
        history.push(`/intake/form/${currentIntakeId}/${page}`);
    };

    // SUBMIT DIALOG ACTIONS
    const [dateRequestedByError, setDateRequestedByError] = useState(false);
    const handleDateRequestedByChange = (date) => {
        setDateRequestedBy(Moment(date).format());
    };
    const handleSubmitCommentsChange = (event) => {
        setSubmitComments(event.target.value);
    };

    const submitIntake = () => {
        if (dateRequestedBy === '' || dateRequestedBy === 'Invalid date') {
            setError(true);
        } else {
            setError(false);
            // duplicate our current data so we don't mutate state
            const tempIntake = { ...currentIntake };

            // Replace item at index using native splice
            tempIntake.status = 'In Review';
            if (submitComments) {
                tempIntake.submitComments = submitComments;
            }
            tempIntake.dateRequestedBy = dateRequestedBy;

            // Google Analytics Items
            // 339: County of current charge(s)
            const question339 = _.find(tempIntake.answers.pages[0].questions, { id: 339 });
            if (question339) QuestionRecorder(question339.label, question339.userInput);

            // 349: What is the charged offense?
            const question349 = _.find(tempIntake.answers.pages[0].questions, { id: 349 });
            if (question349) QuestionRecorder(question349.label, question349.userInput);

            // 66: Are you now a Legal Permanent Resident?
            const question66 = _.find(tempIntake.answers.pages[2].questions, { id: 66 });
            if (question66) QuestionRecorder(question66.label, question66.userInput);

            // 518: Do you have or have you had any of the following?
            const question518 = _.find(tempIntake.answers.pages[2].questions, { id: 518 });
            if (question518) QuestionRecorder(question518.label, question518.userInput.toString());

            // 92: Did you enter without documentation (without papers / without authorization)?
            const question92 = _.find(tempIntake.answers.pages[2].questions, { id: 92 });
            if (question92) QuestionRecorder(question92.label, question92.userInput);

            dispatch(UpdateIntake(tempIntake));
            setDialogOpen(false);
        }
    };

    const PickerTheme = theme => createMuiTheme({
        ...theme,
        overrides: {
            MuiPickersCalendarHeader: {
                dayLabel: {
                    color: Colors.darkElectricBlue,
                },
            },
        },
    });

    return (
        <>
            <header className={`container-fluid ${classes.headerWrapper}`}>
                <Dialog
                    onClose={() => setDialogOpen(false)}
                    open={dialogOpen}
                    maxWidth={false}
                    classes={{
                        paper: classes.dialogPaper,
                    }}
                    TransitionProps={{
                        role: 'none',
                    }}
                >
                    <div className={`row no-gutters ${classes.closeIconWrapper}`}>
                        <div className="col" />
                        <div className="col text-right">
                            <IconButton aria-label="close" className={classes.closeButton} onClick={() => setDialogOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.dialogWrapper}>
                        <Typography variant="h1" className={classes.dialogTitle} style={{ paddingTop: 0 }}>Finish &amp; Submit</Typography>
                        <MuiDialogContent className={classes.contentWrapper}>
                            <div className="row text-left" style={{ paddingBottom: 60 }}>
                                <div className="col">
                                    <div className={classes.inputWrapper}>
                                        <Typography variant="h3" component="h2" className={classes.h3Text}>
                                            By when do you need an advisal?
                                            <sup className={classes.requiredAsterisk}>*</sup>
                                        </Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <ThemeProvider theme={PickerTheme}>
                                                <KeyboardDatePicker
                                                    className={classes.datePicker}
                                                    disableToolbar
                                                    variant="inline"
                                                    // id="date-picker-inline"
                                                    inputVariant="outlined"
                                                    format="MM/DD/YYYY"
                                                    placeholder="mm/dd/yyyy"
                                                    emptyLabel=""
                                                    inputProps={{
                                                        'aria-label': 'By when do you need an advisal?',
                                                    }}
                                                    value={dateRequestedBy === '' ? null : dateRequestedBy}
                                                    onChange={handleDateRequestedByChange}
                                                    helperText={dateRequestedByError ? <span style={{ marginLeft: -10 }}>Invalid Date. MM/DD/YYYY format required.</span> : ''}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    rightArrowButtonProps={{
                                                        'aria-label': 'next month',
                                                    }}
                                                    leftArrowButtonProps={{
                                                        'aria-label': 'previous month',
                                                    }}
                                                    onError={(errResponse) => {
                                                        if (errResponse !== '') {
                                                            setDateRequestedByError(true);
                                                        } else {
                                                            setDateRequestedByError(false);
                                                        }
                                                    }}
                                                    onAccept={() => {
                                                        setDateRequestedByError(false);
                                                    }}
                                                />
                                            </ThemeProvider>
                                        </MuiPickersUtilsProvider>
                                        <Typography variant="subtitle1" component="h3">
                                            <sup>*</sup>
                                            Please refer to the&nbsp;
                                            <Link to="/terms" className={classes.tosLink} target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>
                                            &nbsp;for turnaround time.
                                        </Typography>
                                        <div role="status" aria-live="polite">
                                            {error && <Typography variant="body2" className={classes.error}>Advisal Request Date Required</Typography>}
                                        </div>
                                    </div>
                                    <div className={classes.inputWrapper}>
                                        <Typography variant="h3" component="h2" className={classes.h3Text}>
                                            Are there any additional comments you would like to add?
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={4}
                                            placeholder="Add your comments here"
                                            variant="outlined"
                                            value={submitComments}
                                            onChange={handleSubmitCommentsChange}
                                            fullWidth
                                            style={{ backgroundColor: 'white', fontWeight: 400 }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.textField,
                                                },
                                                inputProps: {
                                                    'aria-label': 'Add comments',
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions className={classes.actionsWrapper}>
                            <Button onClick={() => setDialogOpen(false)} className={classes.greyButton}>
                                Cancel
                            </Button>
                            <Button autoFocus onClick={submitIntake} className={classes.greenButton}>
                                Submit
                            </Button>
                        </MuiDialogActions>
                    </div>
                </Dialog>
                <div className="row align-items-center" style={{ height: '100%' }}>
                    <div className="col d-flex flex-direction-row">
                        <img src={LogoFile} alt="" className={classes.logo} />
                        <Typography variant="h1" style={{ color: 'white' }}>
                            {currentIntake.clientLastName && currentIntake.clientLastName}
                            ,&nbsp;
                            {currentIntake.clientFirstName && currentIntake.clientFirstName}
                        </Typography>
                    </div>
                    <div className="col text-right">
                        <Button className={classes.primaryLink} onClick={() => history.push('/')}>
                            Back to Dashboard
                        </Button>
                        <Button
                            className={classes.greenButton}
                            disabled={!readyToSubmit}
                            onClick={() => setDialogOpen(true)}
                            classes={{
                                label: classes.greenButtonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.greenButtonRippleChildPulsate,
                                },
                            }}
                        >
                            Finish & Submit
                        </Button>
                    </div>
                </div>
                {/* END SUBMIT DIALOG */}
            </header>
            <div className="container">
                <div className={classes.wrapper}>
                    <div className="row">
                        <div className="col">
                            <Typography variant="h1">Intake Dashboard</Typography>
                            <ul>
                                <li><Typography variant="body2" style={{ fontWeight: 400 }}>There are 3 sections. You can start with Step 1, 2, or 3 depending on what best fits your situation.</Typography></li>
                                <li><Typography variant="body2" style={{ fontWeight: 400 }}>You can save and exit a section at any time. The section will appear complete once all the required questions are answered.</Typography></li>
                                <li><Typography variant="body2" style={{ fontWeight: 400 }}>Once all 3 sections are complete, you can finish and submit the form for review.</Typography></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-center" style={{ paddingTop: 90 }}>
                        <div className="col-4">
                            <div className={classes.formWrapper}>
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status !== 'complete' && <div className={classes.formNumber}>1</div>}
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'complete' && <div className={`${classes.formNumber} ${classes.formNumberGreen}`}><DoneIcon className={classes.formDoneIcon} /></div>}

                                {currentIntake.answers.pages[0] && (currentIntake.answers.pages[0].status === 'not started') && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Not Started</Typography>}
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'draft' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>In Progress</Typography>}
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'complete' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Complete</Typography>}
                                <Typography variant="h2" style={{ paddingTop: 10, paddingBottom: 30 }}>Current Charges</Typography>
                                <Typography variant="body2" className={classes.formSummary}>Enter information about the pending criminal case</Typography>
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'not started' && (
                                    <Button
                                        aria-label="Start Step 1"
                                        className={classes.greenButton}
                                        onClick={() => gotoPage('1')}
                                        classes={{
                                            label: classes.greenButtonLabel,
                                        }}
                                        TouchRippleProps={{
                                            classes: {
                                                childPulsate: classes.greenButtonRippleChildPulsate,
                                            },
                                        }}
                                    >
                                        START
                                    </Button>
                                )}
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'draft' && <Button aria-label="Edit Step 1" className={classes.greyButton} onClick={() => gotoPage('1')}>EDIT</Button>}
                                {currentIntake.answers.pages[0] && currentIntake.answers.pages[0].status === 'complete' && <Button aria-label="Edit Step 1" className={classes.greyButton} onClick={() => gotoPage('1')}>EDIT</Button>}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={classes.formWrapper}>
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status !== 'complete' && <div className={classes.formNumber}>2</div>}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'complete' && <div className={`${classes.formNumber} ${classes.formNumberGreen}`}><DoneIcon className={classes.formDoneIcon} /></div>}

                                {currentIntake.answers.pages[1] && (currentIntake.answers.pages[1].status === 'not started') && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Not Started</Typography>}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'draft' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>In Progress</Typography>}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'complete' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Complete</Typography>}
                                <Typography variant="h2" style={{ paddingTop: 10, paddingBottom: 30 }}>Criminal History</Typography>
                                <Typography variant="body2" className={classes.formSummary}>Enter information about your client&#39;s comprehensive priror criminal history</Typography>
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'not started' && (
                                    <Button
                                        aria-label="Start Step 2"
                                        className={classes.greenButton}
                                        onClick={() => gotoPage('2')}
                                        classes={{
                                            label: classes.greenButtonLabel,
                                        }}
                                        TouchRippleProps={{
                                            classes: {
                                                childPulsate: classes.greenButtonRippleChildPulsate,
                                            },
                                        }}
                                    >
                                        START
                                    </Button>
                                )}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'draft' && <Button aria-label="Edit Step 2" className={classes.greyButton} onClick={() => gotoPage('2')}>EDIT</Button>}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[1].status === 'complete' && <Button aria-label="Edit Step 2" className={classes.greyButton} onClick={() => gotoPage('2')}>EDIT</Button>}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={classes.formWrapper}>
                                {currentIntake.answers.pages[2] && currentIntake.answers.pages[2].status !== 'complete' && <div className={classes.formNumber}>3</div>}
                                {currentIntake.answers.pages[2] && currentIntake.answers.pages[2].status === 'complete' && <div className={`${classes.formNumber} ${classes.formNumberGreen}`}><DoneIcon className={classes.formDoneIcon} /></div>}

                                {currentIntake.answers.pages[2] && (currentIntake.answers.pages[2].status === 'not started') && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Not Started</Typography>}
                                {currentIntake.answers.pages[2] && currentIntake.answers.pages[2].status === 'draft' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>In Progress</Typography>}
                                {currentIntake.answers.pages[2] && currentIntake.answers.pages[2].status === 'complete' && <Typography variant="subtitle1" component="p" style={{ paddingTop: 40 }}>Complete</Typography>}
                                <Typography variant="h2" className={classes.formTitle}>Client Info</Typography>
                                <Typography variant="body2" className={classes.formSummary}>Ask your client about imm status &amp; history, family, and more</Typography>
                                {currentIntake.answers.pages[2] && currentIntake.answers.pages[2].status === 'not started' && (
                                    <Button
                                        aria-label="Start Step 3"
                                        className={classes.greenButton}
                                        onClick={() => gotoPage('3')}
                                        classes={{
                                            label: classes.greenButtonLabel,
                                        }}
                                        TouchRippleProps={{
                                            classes: {
                                                childPulsate: classes.greenButtonRippleChildPulsate,
                                            },
                                        }}
                                    >
                                        START
                                    </Button>
                                )}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[2].status === 'draft' && <Button aria-label="Edit Step 3" className={classes.greyButton} onClick={() => gotoPage('3')}>EDIT</Button>}
                                {currentIntake.answers.pages[1] && currentIntake.answers.pages[2].status === 'complete' && <Button aria-label="Edit Step 3" className={classes.greyButton} onClick={() => gotoPage('3')}>EDIT</Button>}
                                <div className={classes.clientRequiredWrapper}>
                                    <img src={ClientRequiredImg} alt="" className={classes.clientRequiredImg} />
                                    <Typography variant="subtitle1" component="h3" className={classes.clientRequiredTxt}>Client Required</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
IntakeDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles, InputsStyles);
export default withStyles(combinedStyles)(IntakeDashboard);
