/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Styles from './styles';
import SelectStyles from '../styles';

import CombineStyles from '../../../utils/combine-styles';

/*
    TODO: initial values need to be populated with fully formed county object
    to hydrate this component. This has not been done, because it isn't need for signup
*/
const CountySelect = ({
    classes,
    handleCountyChange,
    error,
    initialValues,
    counties,
}) => {
    const [inputValue, setInputValue] = useState([]);
    const [countySelectValue, setCountySelectValue] = useState([]);

    const handleChange = (event, values) => {
        setCountySelectValue(values);

        // we only what to sent the id's up to the parent level
        const countyIds = values.map(countyObj => countyObj.id);
        handleCountyChange(countyIds);
    };

    return (
        <div style={{ width: 332, maxWidth: '100%' }}>
            <Autocomplete
                multiple
                // disableCloseOnSelect
                autoHighlight
                onChange={handleChange}
                id="tags-filled"
                options={counties}
                // options={counties.map(option => option.county)}
                getOptionLabel={option => option.county}
                renderOption={option => (
                    <React.Fragment>
                        {option.county}
                    </React.Fragment>
                )}
                defaultValue={initialValues}
                filterSelectedOptions
                renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        size="small"
                        label={option.county}
                        {...getTagProps({ index })}
                        className={classes.chipWrapper}
                    />
                ))}
                onInputChange={(event, value) => setInputValue(value)}
                renderInput={(params) => { // eslint-disable-line arrow-body-style
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Counties you practice in"
                            margin="normal"
                            error={error}
                            fullWidth
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: (countySelectValue.length > 0 || inputValue.length > 0),
                                classes: {
                                    root: classes.inputLabelRoot,
                                    focused: classes.inputLabelFocused,
                                    error: classes.inputLabelError,
                                    shrink: classes.inputLabelShrink,
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                classes: {
                                    root: classes.inputRoot,
                                    focused: classes.inputFocus,
                                    error: classes.inputError,
                                    notchedOutline: classes.inputNotchedOutline,
                                },
                            }}
                        />
                    );
                }}
            />
        </div>
    );
};

CountySelect.defaultProps = {
    initialValues: [],
};

CountySelect.propTypes = {
    classes: PropTypes.object.isRequired,
    handleCountyChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    initialValues: PropTypes.array,
    counties: PropTypes.array.isRequired,
};

const combinedStyles = CombineStyles(Styles, SelectStyles);

export default withStyles(combinedStyles)(CountySelect);
