import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { orderBy, cloneDeep } from 'lodash';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from './images/arrow_drop_down-24px-edit.svg';
import ArrowDropUpIcon from './images/arrow_drop_up-24px-edit.svg';

import ButtonActionsPopover from '../../../components/admin/edit-delete-popover';
import DeleteDialog from '../../../components/admin/delete-dialog';

import Pagination from './components/pagination';
import SuccessBanner from '../../../components/feedback-banners/success';

import { UpdateIntakeBegin } from '../../../actions/intakes';

import Styles from './styles';

const AllForms = ({ classes }) => {
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;

    const intakes = useSelector(state => state.intakes.intakes);
    const initialFormLoad = useSelector(state => state.intakes.getIntakes.completed);
    const intakeSubmitted = useSelector(state => state.intakes.intakeSubmitted);

    const history = useHistory();
    const dispatch = useDispatch();

    const [filter, setFilter] = useState('all');
    const [filteredList, setFilteredList] = useState([]);

    const [sort, setSort] = useState({ iteratees: '', orders: '' });
    const [sortedList, setSortedList] = useState([]);

    const [listItemsToDisplay, setListItemsToDisplay] = useState([]);

    const [buttonActionsAnchorEl, setButtonActionsAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dialogValues, setDialogValues] = useState({ id: null, name: '' });
    const handleButtonActionsClose = () => {
        setButtonActionsAnchorEl(null);
    };
    const handleButtonActionsClick = values => (event) => {
        setDialogValues(values);
        setButtonActionsAnchorEl(event.currentTarget);
    };
    const closeDeleteDialog = () => {
        setButtonActionsAnchorEl(null);
        setDeleteDialogOpen(false);
    };

    const clearBanner = () => {
        dispatch(UpdateIntakeBegin());
    };

    useEffect(() => {
        document.title = 'myPadilla: All Forms';
        // clear success banner if it is present
        return () => clearBanner();
    }, []);

    // raw -> filter -> sort -> display
    useEffect(() => {
        setPage(0);
        if (filter === 'all') {
            setFilteredList(intakes);
        } else {
            const filteredData = intakes.filter(form => (form.status ? form.status.toLowerCase() : '') === filter);
            setFilteredList(filteredData);
        }
    }, [intakes, filter]);

    useEffect(() => {
        const sortedArray = orderBy(filteredList, [sort.iteratees], [sort.orders]);
        setSortedList(sortedArray);
    }, [filteredList, sort]);

    useEffect(() => {
        const sectionedData = cloneDeep(sortedList);
        const temp = sectionedData.slice((page * itemsPerPage), (page * itemsPerPage) + itemsPerPage);
        setListItemsToDisplay(temp);
    }, [page, sortedList]);

    return (
        <div className="container">
            <div className={classes.wrapper}>
                <div role="status" aria-live="polite">
                    {intakeSubmitted && (
                        <SuccessBanner text="Your intake form has been submitted. You will receive an email notification when the advisal is ready." clearBanner={clearBanner} />
                    )}
                </div>
                <div className="row align-items-center" style={{ height: 90 }}>
                    <div className="col p-0">
                        <Typography variant="h1">All Forms</Typography>
                    </div>
                    <div className="col-auto">
                        <button className={`${classes.filterBtn} ${filter === 'all' && classes.filterBtnActive}`} type="button" onClick={() => setFilter('all')}>
                            All
                        </button>
                        <button className={`${classes.filterBtn} ${filter === 'draft' && classes.filterBtnActive}`} type="button" onClick={() => setFilter('draft')}>
                            Draft
                        </button>
                        <button className={`${classes.filterBtn} ${filter === 'in review' && classes.filterBtnActive}`} type="button" onClick={() => setFilter('in review')}>
                            In Review
                        </button>
                        <button className={`${classes.filterBtn} ${filter === 'complete' && classes.filterBtnActive}`} type="button" onClick={() => setFilter('complete')}>
                            Completed
                        </button>
                    </div>
                </div>

                <div className={`row align-items-center ${classes.tableHeader}`}>
                    <div className="col-fixed" style={{ width: 120 }}>
                        <Typography variant="body1">STATUS</Typography>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <div className={classes.sortWrapper}>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'clientLastName', orders: 'asc' })}>
                                <img src={ArrowDropUpIcon} alt="Sort by client - ascending" />
                            </button>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'clientLastName', orders: 'desc' })}>
                                <img src={ArrowDropDownIcon} alt="Sort by client - descending" />
                            </button>
                        </div>
                        <Typography variant="body1" className={classes.rowTitleText}>CLIENT</Typography>
                    </div>
                    <div className="col d-none d-lg-flex">
                        <Typography variant="body1">CAUSE #</Typography>
                    </div>
                    <div className="col-auto d-none d-lg-flex align-items-center">
                        <div className={classes.sortWrapper}>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'dateSubmitted', orders: 'asc' })}>
                                <img src={ArrowDropUpIcon} alt="Sort by date submitted - ascending" />
                            </button>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'dateSubmitted', orders: 'desc' })}>
                                <img src={ArrowDropDownIcon} alt="Sort by date submitted - descending" />
                            </button>
                        </div>
                        <Typography variant="body1" className={classes.rowTitleText}>DATE SUBMITTED</Typography>
                    </div>
                    <div className="col-auto d-none d-lg-flex align-items-center">
                        <div className={classes.sortWrapper}>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'dateRequestedBy', orders: 'asc' })}>
                                <img src={ArrowDropUpIcon} alt="Sort by date requested by - ascending" />
                            </button>
                            <button className={classes.sortBtn} type="button" onClick={() => setSort({ iteratees: 'dateRequestedBy', orders: 'desc' })}>
                                <img src={ArrowDropDownIcon} alt="Sort by date requested by - descending" />
                            </button>
                        </div>
                        <Typography variant="body1" className={classes.rowTitleText}>REQUESTED BY</Typography>
                    </div>
                    <div className="col" />
                </div>
                {(listItemsToDisplay.length <= 0 && initialFormLoad === 'completed') && (
                    <div
                        className="row"
                        style={{
                            backgroundColor: 'white',
                            height: 200,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="body1">You haven&lsquo;t created any new intake forms yet</Typography>
                    </div>
                )}
                {listItemsToDisplay.length > 0 && (
                    listItemsToDisplay.map((form) => {
                        let actionBtns;
                        let statusIcon;
                        const formStatus = form.status ? form.status.toLowerCase() : '';
                        if (formStatus === 'complete') {
                            statusIcon = classes.statusIconCompleted;
                            if (form.advisalUrl) {
                                actionBtns = (
                                    <div className="col justify-content-end d-flex">
                                        <a className={classes.tableRowButton} href={form.advisalUrl} target="_blank" rel="noopener noreferrer">View Advice</a>
                                    </div>
                                );
                            } else {
                                actionBtns = (
                                    <div className="col" />
                                );
                            }
                        } else if (formStatus === 'in review') {
                            statusIcon = classes.statusIconReview;
                            actionBtns = (
                                <div className="col" />
                            );
                        } else if (formStatus === 'draft') {
                            statusIcon = classes.statusIconDraft;
                            actionBtns = (
                                <div className="col justify-content-end d-flex">
                                    <button type="button" className={classes.tableRowButton} onClick={() => history.push(`/intake/dashboard/${form.id}`)}>Edit</button>
                                    <button type="button" className={`${classes.tableRowButton} ${classes.tableRowButtonArrow}`} onClick={handleButtonActionsClick({ id: form.id, name: `${form.clientLastName}, ${form.clientFirstName}` })} aria-label="See other actions"><ExpandMoreIcon className={classes.tableRowExpandIcon} /></button>
                                </div>
                            );
                        }
                        return (
                            <div className={`row align-items-center ${classes.tableRow}`} key={form.id}>
                                <div className="col-fixed" style={{ width: 120 }}>
                                    <div className={`${classes.statusIcon} ${statusIcon}`} />
                                    <Typography variant="body1" style={{ display: 'inline-flex' }} className={classes.statusTextDraft}>{form.status}</Typography>
                                </div>
                                <div className="col-4">
                                    <Typography variant="body1" className={classes.tableRowClientName}>
                                        {form.clientLastName}
                                        ,&nbsp;
                                        {form.clientFirstName}
                                    </Typography>
                                </div>
                                <div className="col d-none d-lg-flex">
                                    <Typography variant="body1" className={classes.tableRowCauseNumber}>{form.causeNumber ? form.causeNumber : '-'}</Typography>
                                </div>
                                <div className="col d-none d-lg-flex">
                                    <Typography variant="body1" className={classes.tableRowDate}>{form.dateSubmitted ? Moment(form.dateSubmitted).format('ll') : '-'}</Typography>
                                </div>
                                <div className="col d-none d-lg-flex">
                                    <Typography variant="body1" className={classes.tableRowDate}>{form.dateRequestedBy ? Moment(form.dateRequestedBy).format('ll') : '-'}</Typography>
                                </div>
                                {actionBtns}
                            </div>
                        );
                    })
                )}
                {/* PAGINATION */}
                {sortedList.length > itemsPerPage && (
                    <Pagination
                        page={page}
                        itemsPerPage={itemsPerPage}
                        listLength={sortedList.length}
                        setPage={setPage}
                    />
                )}
                {/* DRAFT BUTTON ACTION POPOVER */}
                <ButtonActionsPopover handleClose={handleButtonActionsClose} anchorEl={buttonActionsAnchorEl}>
                    <div className={classes.popoverWrapper}>
                        <button
                            type="button"
                            onClick={() => history.push(`/intake/dashboard/${dialogValues.id}`)}
                            className={classes.popoverButton}
                        >
                            Edit
                        </button>
                        <button type="button" className={classes.popoverButton} onClick={() => setDeleteDialogOpen(true)}>Delete</button>
                    </div>
                </ButtonActionsPopover>
                <DeleteDialog handleClose={closeDeleteDialog} open={deleteDialogOpen} id={dialogValues.id} name={dialogValues.name} />
            </div>
        </div>
    );
};
AllForms.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(AllForms);
