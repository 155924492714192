const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    INTAKE_CREATE_BEGIN: true,
    INTAKE_CREATE_SUCCESS: true,
    INTAKE_CREATE_ERROR: true,
    INTAKE_UPDATE_BEGIN: true,
    INTAKE_UPDATE_SUCCESS: true,
    INTAKE_UPDATE_ERROR: true,
    DELETE_ENTRY_BEGIN: true,
    DELETE_ENTRY_SUCCESS: true,
    DELETE_ENTRY_ERROR: true,
    GET_INTAKES_BEGIN: true,
    GET_INTAKES_SUCCESS: true,
    GET_INTAKES_ERROR: true,
    GET_ACTIVE_FORM_BEGIN: true,
    GET_ACTIVE_FORM_SUCCESS: true,
    GET_ACTIVE_FORM_ERROR: true,
});
