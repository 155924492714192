import AuthToken from './maintain-auth-token';
import BaseData from './base-data';

const internals = {
    initializers: [AuthToken, BaseData],
};

export default (store) => {
    internals.initializers.forEach(init => init(store));
};
