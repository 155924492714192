import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import InitializerAdmin from '../initializers/admin';

import PublicLayout from '../layouts/public';
import AdminLayout from '../layouts/admin';
import AdminIntakeLayout from '../layouts/admin/intake';
import Login from './login';

import withRoot from '../wiring/with-root';

import themer from '../styles/material-theme';

const App = ({ store }) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const authRole = useSelector(state => state.auth.credentials.role);

    useEffect(() => {
        if (isAuthenticated) {
            // load role specific content
            if (authRole === 'attorney') {
                InitializerAdmin(store);
            }
        }
    }, [isAuthenticated, authRole]);

    return (
        <MuiThemeProvider theme={themer}>
            <Switch location={location}>
                <Route path="/login">
                    <PublicLayout />
                </Route>
                <Route path="/password-reset">
                    <PublicLayout />
                </Route>
                <Route path="/terms">
                    <PublicLayout />
                </Route>
                <Route path="/intake">
                    {isAuthenticated ? <AdminIntakeLayout /> : <Login />}
                </Route>
                <Route path="/">
                    {isAuthenticated ? <AdminLayout /> : <Login />}
                </Route>
            </Switch>
        </MuiThemeProvider>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withRoot(App);
