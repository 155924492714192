import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Dashboard from '../../routes/admin/intake/dashboard';
import QuestionFlow from '../../routes/admin/intake/question-flow';
import withRoot from '../../wiring/with-root';
import AdminFooter from '../../components/admin/footer/intake-footer';

const IntakeLayout = () => {
    const location = useLocation();
    return (
        <React.Fragment>
            <main style={{ minHeight: '100vh', backgroundColor: '#f2f4f6', paddingBottom: 80 }}>
                <Switch location={location}>
                    <Route path="/intake/dashboard/:id">
                        <Dashboard />
                    </Route>
                    <Route exact path="/intake/form/:id/:page">
                        <QuestionFlow />
                    </Route>
                </Switch>
            </main>
            <AdminFooter />
        </React.Fragment>
    );
};
export default withRoot(IntakeLayout);
