import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import CombineStyles from '../../../utils/combine-styles';
import Styles from './styles';
import ButtonStyles from '../../../styles/buttons';

import { DeleteEntry, DeleteEntryBegin } from '../../../actions/intakes';

const DialogTitle = withStyles(Styles)((props) => {
    const {
        children,
        classes,
        ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({
    root: {},
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
    root: {},
}))(MuiDialogActions);

const DeleteItemDialog = ({
    classes,
    open,
    handleClose,
    id,
    name,
}) => {
    // no error states considered in the design
    // const deleteEntryError = useSelector(state => state.intakes.deleteEntry.error);
    // const deleteEntryErrorMsg = useSelector(state => state.intakes.deleteEntry.errorMsg);
    const deleteEntryCompleted = useSelector(state => state.intakes.deleteEntry.completed);
    const dispatch = useDispatch();
    const deleteForm = () => {
        dispatch(DeleteEntry(id));
    };

    useEffect(() => {
        // we don't check to see if there is an error or not, just that it's done.
        if (deleteEntryCompleted) {
            // after deleteForm
            handleClose();
            // reset state
            dispatch(DeleteEntryBegin());
        }
    }, [deleteEntryCompleted]);

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="delete-dialog"
            open={open}
            maxWidth={false}
            classes={{
                paper: classes.dialogPaper,
            }}
            TransitionProps={{
                role: 'none',
            }}
        >
            <div className={classes.closeIconWrapper}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.dialogWrapper}>
                <DialogTitle id="delete-dialog" onClose={handleClose}>
                    Are you sure you want to delete this form?
                </DialogTitle>
                <DialogContent className={classes.contentWrapper}>
                    <Typography variant="h2" className={classes.nameText}>
                        {name}
                    </Typography>
                    <Typography variant="h3" className={classes.warningText}>
                        Warning: this cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.actionsWrapper}>
                    <Button onClick={handleClose} className={classes.greyButton}>
                        Cancel, keep this form
                    </Button>
                    <Button
                        autoFocus
                        onClick={deleteForm}
                        className={classes.redButton}
                        classes={{
                            label: classes.greenButtonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.greenButtonRippleChildPulsate,
                            },
                        }}
                    >
                        Yes, delete this form
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

DeleteItemDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};
DeleteItemDialog.defaultProps = {
    id: -1,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles);

export default withStyles(combinedStyles)(DeleteItemDialog);
