import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

const GetCountiesBegin = () => ({
    type: AuthTypes.GET_COUNTIES_BEGIN,
});
const GetCountiesSuccess = payload => ({
    type: AuthTypes.GET_COUNTIES_SUCCESS,
    payload,
});
const GetCountiesError = () => ({
    type: AuthTypes.GET_COUNTIES_ERROR,
});

export const GetCounties = () => (dispatch) => {
    dispatch(GetCountiesBegin());

    const getCounties = WebClient.get('/counties');

    getCounties
        .then(({ data }) => {
            dispatch(GetCountiesSuccess(data));
        })
        .catch(() => {
            dispatch(GetCountiesError());
        });
};

export const LoginBegin = payload => ({
    type: AuthTypes.LOGIN_BEGIN,
    payload,
});
const LoginSuccess = payload => ({
    type: AuthTypes.LOGIN_SUCCESS,
    payload,
});
const LoginError = payload => ({
    type: AuthTypes.LOGIN_ERROR,
    payload,
});

export const Login = formValues => (dispatch) => {
    const { email, password } = formValues;

    dispatch(LoginBegin({ isAuthenticated: false, error: false, errorMsg: '' }));
    const login = WebClient.post(
        '/login',
        { email, password },
        { responseType: 'text' },
    );

    login
        .then(({ data }) => {
            // update auth token
            WebClient.updateAuth(data.token);

            const userData = {
                isAuthenticated: true,
                credentials: {
                    token: data.token,
                    role: data.user.role,
                },
                user: {
                    ...data.user,
                },
                error: false,
                errorMsg: '',
            };
            dispatch(LoginSuccess(userData));
        })
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                errorMsg = error.response.data.errors.unauthorized[0] || "We couldn't find a user with that email address.";
            } else if (error.response && (error.response.status === 422)) {
                errorMsg = "We couldn't find a user with that email address.";
            } else {
                errorMsg = 'Something seems to have gone awry!  Try that again.';
            }
            dispatch(LoginError({ isAuthenticated: false, error: true, errorMsg }));
        });

    return login;
};

export const Logout = () => ({
    type: AuthTypes.LOGOUT,
    payload: { isAuthenticated: false, credentials: { token: '' } },
});

export const ForgotPassBegin = () => ({
    type: AuthTypes.FORGOT_PASSWORD_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const ForgotPassSuccess = payload => ({
    type: AuthTypes.FORGOT_PASSWORD_SUCCESS,
    payload,
});
const ForgotPassError = payload => ({
    type: AuthTypes.FORGOT_PASSWORD_ERROR,
    payload,
});
export const ForgotPass = email => (dispatch) => {
    dispatch(ForgotPassBegin());

    const forgotpassword = WebClient.post('/users/request-reset', { email });

    forgotpassword
        .then(() => dispatch(ForgotPassSuccess({ error: false, errorMsg: '', completed: true })))
        .catch((error) => {
            let errorMsg = 'Error';
            // make sure we have a response object, then check to value of the status
            if (error.response && error.response.status !== 400) {
                errorMsg = 'Something seems to have gone awry!  Try that again.';
            } else if (error.response && error.response.status === 400) {
                errorMsg = error.response.data.errors.badRequest[0] || "We couldn't find a user with that email address.";
            } else {
                errorMsg = 'Something seems to have gone awry!  Try that again.';
            }
            dispatch(ForgotPassError({ error: true, errorMsg, completed: true }));
        });

    return forgotpassword;
};

export const ResetPassBegin = () => ({
    type: AuthTypes.RESET_PASSWORD_BEGIN,
    payload: { error: false, errorMsg: '', completed: false },
});
const ResetPassSuccess = payload => ({
    type: AuthTypes.RESET_PASSWORD_SUCCESS,
    payload,
});
const ResetPassError = payload => ({
    type: AuthTypes.RESET_PASSWORD_ERROR,
    payload,
});
export const ResetPass = formValues => (dispatch) => {
    dispatch(ResetPassBegin());

    const { email, resetToken, newPassword } = formValues;
    const resetpassword = WebClient.post('/users/reset-password', {
        email,
        newPassword,
        resetToken,
    });

    resetpassword
        .then(() => dispatch(ResetPassSuccess({ error: false, errorMsg: '', completed: true })))
        .catch((status) => {
            let errorMsg;
            if (status.response && (
                status.response.status !== 404
                    && status.response.status !== 400
            )) {
                errorMsg = 'Something seems to have gone awry!  Try that again.';
            } else {
                errorMsg = "We couldn't find a user with that email address.";
            }
            dispatch(ResetPassError({ error: true, errorMsg, completed: true }));
        });
    return resetpassword;
};


export const SignUpBegin = payload => ({
    type: AuthTypes.SIGNUP_BEGIN,
    payload,
});
const SignUpSuccess = payload => ({
    type: AuthTypes.SIGNUP_SUCCESS,
    payload,
});
const SignUpError = payload => ({
    type: AuthTypes.SIGNUP_ERROR,
    payload,
});

export const SignUp = formValues => (dispatch) => {
    /*
    endpoint payload
    - email
    - password
    - firstName
    - lastName
    - firm
    - practiceCounties
    - practiceType
    - inviteToken (optional)
    - phone (optional)
    */
    dispatch(SignUpBegin({ completed: false, error: false, errorMsg: '' }));

    const signup = WebClient.post(
        '/users',
        formValues,
    );

    signup
        .then(() => dispatch(SignUpSuccess({ completed: true, error: false, errorMsg: '' })))
        .catch((error) => {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                errorMsg = 'Error: Invalid email or password';
            }
            if (error.response && (error.response.status === 422)) {
                errorMsg = 'Error: Invalid email address';
            }
            dispatch(SignUpError({ completed: true, error: true, errorMsg }));
        });

    return signup;
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => (dispatch) => {
    dispatch(CheckTokenBegin());
    const checkToken = WebClient.get('/users/authenticated');

    checkToken
        .then(() => dispatch(CheckTokenSuccess()))
        .catch(() => {
            dispatch(CheckTokenError());
            dispatch(Logout());
        });
};
