import Colors from './colors';

const styles = () => ({
    inputWrapper: {
        margin: '23px 0 0 0',
        width: '100%',
    },
    textInput: {
        height: 50,
        fontWeight: 400,
        backgroundColor: 'white',
        '&$textInputError': {
            '& fieldset': {
                borderColor: `${Colors.raspberryRose} !important`,
            },
        },
    },
    textInputError: {},
    textInputErrorFocused: {},
    textInputLabelRoot: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 15,
        color: Colors.darkElectricBlue,
        '&$textInputLabelFocused': { color: Colors.darkJungleGreen },
        '&$textInputLabelError': { color: Colors.darkElectricBlue },
    },
    textInputLabelFocused: {},
    textInputLabelError: {},
    textInputLabelErrorFocused: {},
    selectWrapper: {
        width: '100%',
        fontSize: 30,
        color: Colors.darkJungleGreen,
        minWidth: 282,
    },
    selectMenuRoot: {
        fontWeight: 400,
        fontSize: 15,
        backgroundColor: 'white',
        textAlign: 'left',
        '&$selectMenuError': {
            '& fieldset': {
                borderColor: '#BB5067 !important',
            },
        },
    },
    selectMenuError: {},
    selectMenuTitle: {
        color: Colors.darkElectricBlue,
        fontSize: 12,
    },
    selectMenuItem: {
        fontSize: 14,
        marginLeft: 15,
    },
    errorMessage: {
        fontSize: 15,
        fontWeight: 500,
        textAlign: 'left',
        color: Colors.darkRed,
    },
    successMessage: {
        fontSize: 15,
        fontWeight: 500,
        textAlign: 'left',
        color: Colors.moss,
    },
    datePicker: {
        '& div': {
            backgroundColor: 'white',
            borderRadius: 5,
            fontWeight: 400,
        },
    },
});
export default styles;
