import Colors from '../../../../../styles/colors';

const styles = () => ({
    checkbox: {
        color: Colors.cadetGrey,
    },
    checkboxLabel: {
        fontSize: 15,
    },
});

export default styles;
