import Colors from '../../../styles/colors';

const styles = () => ({
    wrapper: {
        color: 'white',
        backgroundColor: Colors.darkJungleGreen,
        borderBottom: '1px solid black',
        minHeight: 80,
        display: 'flex',
    },
    navButtonsWrapper: {
        alignItems: 'flex-end',
        '@media (max-width: 630px)': {
            paddingTop: 15,
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
    },
    logo: {
        height: 37,
        marginRight: 10,
    },
    welcomeText: {
        display: 'inline',
        marginLeft: 30,
    },
});

export default styles;
