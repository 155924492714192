import { createMuiTheme } from '@material-ui/core/styles';
import '../fonts/Inter/inter.css';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Inter, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        useNextVariants: true,
        body1: {
            fontSize: 13,
            fontWeight: 400,
        },
        body2: {
            fontSize: 14,
            fontWeight: 800,
        },
        h1: {
            fontSize: 22,
            fontWeight: 800,
            lineHeight: 2,
        },
        h2: {
            fontSize: 17,
            fontWeight: 800,
        },
        h3: {
            fontSize: 15,
            fontWeight: 700,
        },
        h4: {
            fontSize: 24,
            fontWeight: 600,
        },
        subtitle1: {
            fontSize: 12,
            color: Colors.darkElectricBlue,
        },
    },
    palette: {
        primary: {
            main: Colors.mediumAquamarine,
            contrastText: '#192A39',
        },
        secondary: {
            main: Colors.gainsboro,
        },
        background: {
            default: Colors.darkJungleGreen,
        },
        error: {
            main: Colors.darkRed,
        },
    },
});

export default theme;
