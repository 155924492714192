import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DownloadIcon from '@material-ui/icons/GetApp';
import WithYourClientImg from './images/with_your_client_2x.png';
import AtYourOfficeImg from './images/at_your_office_2x.png';

import ButtonActionsPopover from '../../../components/admin/edit-delete-popover';
import DeleteDialog from '../../../components/admin/delete-dialog';

import InternetIcon from './images/Fill_1.png';

import CombineStyles from '../../../utils/combine-styles';
import Styles from './styles';
import ButtonStyles from '../../../styles/buttons';

const Dashboard = ({ classes }) => {
    useEffect(() => {
        document.title = 'myPadilla: Dashboard';
    }, []);
    const forms = useSelector(state => state.intakes.intakes);
    const initialFormLoad = useSelector(state => state.intakes.getIntakes.completed);
    const history = useHistory();
    const [recentForms, setRecentForms] = useState([]);
    const [buttonActionsAnchorEl, setButtonActionsAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dialogValues, setDialogValues] = useState({ id: null, name: '' });
    const handleButtonActionsClose = () => {
        setButtonActionsAnchorEl(null);
    };
    const handleButtonActionsClick = values => (event) => {
        setDialogValues(values);
        setButtonActionsAnchorEl(event.currentTarget);
    };
    const closeDeleteDialog = () => {
        setButtonActionsAnchorEl(null);
        setDeleteDialogOpen(false);
    };

    useEffect(() => {
        const recentlyAdded = forms.slice(0, 4);
        setRecentForms(recentlyAdded);
    }, [forms]);

    return (
        <div className="container">
            <div className={classes.wrapper}>
                <div className="row align-items-center" style={{ height: 70, alignContent: 'end' }}>
                    <div className="col p-0">
                        <Typography variant="h1">Recent Intake Forms</Typography>
                    </div>
                    {forms.length > 4 && (
                        <div className="col-auto">
                            <button
                                type="button"
                                className={classes.viewAllBtn}
                                onClick={() => history.push('/all-intakes')}
                            >
                                View All
                                <div className={classes.viewAllIconWrapper}>
                                    <ArrowForwardIcon className={classes.viewAllIcon} />
                                </div>
                            </button>
                        </div>
                    )}
                </div>
                {(recentForms.length <= 0 && initialFormLoad) && (
                    <div
                        className="row"
                        style={{
                            backgroundColor: 'white',
                            height: 200,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="body1">You haven&lsquo;t created any new intake forms yet</Typography>
                    </div>
                )}
                {recentForms.length > 0 && (
                    <>
                        <div className={`row align-items-center ${classes.tableHeader}`}>
                            <div className="col-fixed" style={{ width: 120 }}>
                                <Typography variant="body1">STATUS</Typography>
                            </div>
                            <div className="col-4">
                                <Typography variant="body1">CLIENT</Typography>
                            </div>
                            <div className="col d-none d-lg-flex">
                                <Typography variant="body1">CAUSE #</Typography>
                            </div>
                            <div className="col d-none d-lg-flex">
                                <Typography variant="body1">DATE SUBMITTED</Typography>
                            </div>
                            <div className="col d-none d-lg-flex">
                                <Typography variant="body1">REQUESTED BY</Typography>
                            </div>
                            <div className="col" />
                        </div>
                        {recentForms.map((form) => {
                            let actionBtns;
                            let statusIcon;
                            const formStatus = form.status.toLowerCase();
                            if (formStatus === 'complete') {
                                statusIcon = classes.statusIconCompleted;
                                if (form.advisalUrl) {
                                    actionBtns = (
                                        <div className="col justify-content-end d-flex">
                                            <a className={classes.tableRowButton} href={form.advisalUrl} target="_blank" rel="noopener noreferrer">View Advice</a>
                                        </div>
                                    );
                                } else {
                                    actionBtns = (
                                        <div className="col" />
                                    );
                                }
                            } else if (formStatus === 'in review') {
                                statusIcon = classes.statusIconReview;
                                actionBtns = (
                                    <div className="col" />
                                );
                            } else if (formStatus === 'draft') {
                                statusIcon = classes.statusIconDraft;
                                actionBtns = (
                                    <div className="col justify-content-end d-flex">
                                        <button type="button" className={classes.tableRowButton} onClick={() => history.push(`/intake/dashboard/${form.id}`)}>Edit</button>
                                        <button type="button" className={`${classes.tableRowButton} ${classes.tableRowButtonArrow}`} onClick={handleButtonActionsClick({ id: form.id, name: `${form.clientLastName}, ${form.clientFirstName}` })} aria-label="See other actions"><ExpandMoreIcon className={classes.tableRowExpandIcon} /></button>
                                    </div>
                                );
                            }
                            return (
                                <div className={`row align-items-center ${classes.tableRow}`} key={form.id}>
                                    <div className="col-fixed" style={{ width: 120 }}>
                                        <div className={`${classes.statusIcon} ${statusIcon}`} />
                                        <Typography variant="body1" style={{ display: 'inline-flex' }} className={classes.statusTextDraft}>{form.status}</Typography>
                                    </div>
                                    <div className="col-4">
                                        <Typography variant="body1" className={classes.tableRowClientName}>
                                            {form.clientLastName}
                                            ,&nbsp;
                                            {form.clientFirstName}
                                        </Typography>
                                    </div>
                                    <div className="col d-none d-lg-flex">
                                        <Typography variant="body1" className={classes.tableRowCauseNumber}>{form.causeNumber ? form.causeNumber : '-'}</Typography>
                                    </div>
                                    <div className="col d-none d-lg-flex">
                                        <Typography variant="body1" className={classes.tableRowDate}>{form.dateSubmitted ? Moment(form.dateSubmitted).format('ll') : '-'}</Typography>
                                    </div>
                                    <div className="col d-none d-lg-flex">
                                        <Typography variant="body1" className={classes.tableRowDate}>{form.dateRequestedBy ? Moment(form.dateRequestedBy).format('ll') : '-'}</Typography>
                                    </div>
                                    {actionBtns}
                                </div>
                            );
                        })}
                    </>
                )}
                {/* DRAFT BUTTON ACTION POPOVER */}
                <ButtonActionsPopover handleClose={handleButtonActionsClose} anchorEl={buttonActionsAnchorEl}>
                    <div className={classes.popoverWrapper} role="main">
                        <button
                            type="button"
                            onClick={() => history.push(`/intake/dashboard/${dialogValues.id}`)}
                            className={classes.popoverButton}
                        >
                            Edit
                        </button>
                        <button type="button" className={classes.popoverButton} onClick={() => setDeleteDialogOpen(true)}>Delete</button>
                    </div>
                </ButtonActionsPopover>
                <DeleteDialog handleClose={closeDeleteDialog} open={deleteDialogOpen} id={dialogValues.id} name={dialogValues.name} />

                {/* HOW IT WORKS */}
                <div className="row text-center" style={{ paddingTop: 60, paddingBottom: 40 }}>
                    <div className="col">
                        <Typography variant="h1" style={{ lineHeight: 'auto' }}>How it Works</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{ marginRight: 20 }}>
                        <div className={`row align-items-center ${classes.blockTop}`}>
                            <div className="col">
                                <img src={AtYourOfficeImg} className={classes.blockTopIcon} alt="" />
                                <Typography variant="h2" className={classes.blockTopTitle}>At your office</Typography>
                            </div>
                            <div className="col text-right">
                                <Typography variant="body1">5-15 min</Typography>
                            </div>
                        </div>
                        <div className={`row ${classes.blockBottom}`}>
                            <div className="col">
                                <Typography variant="body1"><strong>Enter Information about the pending criminal case</strong></Typography>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Charge &amp; Classification/Level</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Dates of arrest & alleged commission</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Charging instrument (to upload)</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Plea offer or anticipated plea offer, if any</Typography>
                                </div>
                                <Typography variant="body1" style={{ paddingTop: 20 }}><strong>Enter information about your client’s prior criminal history</strong></Typography>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Date, statute, & type of disposition</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Judgment, charging doc, docket (to upload)</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ marginLeft: 20 }}>
                        <div className={`row align-items-center ${classes.blockTop} ${classes.blockTopOrange}`}>
                            <div className="col d-flex align-items-center">
                                <img src={WithYourClientImg} className={classes.blockTopIcon} alt="" />
                                <Typography variant="h2" className={classes.blockTopTitle}>With your client</Typography>
                            </div>
                            <div className="col text-right">
                                <Typography variant="body1">15-30 min</Typography>
                            </div>
                        </div>
                        <div className={`row ${classes.blockBottom}`}>
                            <div className="col">
                                <Typography variant="body1"><strong>You will need to ask your client about</strong></Typography>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Immigration status & history</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Family relationships</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Eligibility for immigration relief</Typography>
                                </div>
                                <div className={classes.howItWorksTextWrapper}>
                                    <CheckCircleIcon className={classes.checkIcon} />
                                    <Typography variant="body1" className={classes.howItWorksText}>Criminal history</Typography>
                                </div>

                                <Typography variant="body1" style={{ paddingTop: 20 }}><strong>Prepare your client</strong></Typography>
                                <Typography variant="body1" style={{ paddingBottom: 20 }}>Give your client this document to prepare them for the conversation:</Typography>
                                <button type="button" className={classes.greenBorderBtn} onClick={() => window.open('/myPadilla_Client_Prep_01_2020.pdf')}>
                                    <DownloadIcon />
                                    Client Prep Doc (English &amp; Spanish)
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row no-gutters ${classes.noInternetWrapper}`}>
                    <div className={`col d-flex align-items-center justify-content-center ${classes.noInternetLeftCol}`}>
                        <img src={InternetIcon} alt="" style={{ width: 34, height: 34 }} />
                    </div>
                    <div className="col d-flex align-items-center" style={{ paddingLeft: 25 }}>
                        <Typography variant="h2" component="h3" className={classes.noInternetTitle}>No internet access?</Typography>
                        <Typography variant="body1" style={{ display: 'inline' }}>Download the client interview questions to use offline.</Typography>
                    </div>
                    <div className="col-auto d-flex align-items-center justify-content-end" style={{ paddingRight: 45 }}>
                        <button type="button" className={classes.greenBorderBtn} onClick={() => window.open('./myPadilla_client_quesetions_v1.0.pdf')}>
                            <DownloadIcon />
                            Client Interview Questions (English)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(Styles, ButtonStyles);
export default withStyles(combinedStyles)(Dashboard);
