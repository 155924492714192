import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CombineStyles from '../../../../../utils/combine-styles';
import Styles from './checkbox-styles';
import InputStyles from './input-styles';


const CheckBoxes = ({
    classes,
    questionProps,
    manageFormInputs,
}) => {
    const {
        choices,
        id,
        userInput,
        label,
    } = questionProps;

    const handleChange = name => (event) => {
        // if (_.includes(userInput, name)) {
        if (!event.target.checked) {
            // remove from the array
            const selectedInputs = [...userInput];
            const index = selectedInputs.indexOf(name);

            selectedInputs.splice(index, 1);
            // send updates to the parent component
            manageFormInputs({ id, userInput: selectedInputs });
        } else {
            // add to the array
            let selectedInputs = [...userInput];
            selectedInputs = selectedInputs.concat(name);
            // send updates to the parent component
            manageFormInputs({ id, userInput: selectedInputs });
        }
    };

    return (
        <FormGroup component="fieldset" className={classes.questionInputWrapper}>
            <FormLabel component="legend" className="screen-reader-only">{label}</FormLabel>
            {choices.map((choice) => {
                let selected = false;

                if (_.includes(userInput, choice.value)) {
                    selected = true;
                }
                return (
                    <FormControlLabel
                        control={<Checkbox className={classes.checkbox} color="primary" checked={selected} onChange={handleChange(choice.value)} value={choice.value} />}
                        label={choice.text}
                        key={choice.text}
                        classes={{
                            label: classes.checkboxLabel,
                        }}
                    />
                );
            })}
        </FormGroup>
    );
};

CheckBoxes.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        choices: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        userInput: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
};
CheckBoxes.defaultProps = {
    questionProps: {},
};

const combinedStyles = CombineStyles(Styles, InputStyles);
export default withStyles(combinedStyles)(CheckBoxes);
