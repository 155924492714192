import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import { withStyles } from '@material-ui/core/styles';

import CombineStyles from '../../../../../utils/combine-styles';
import ButtonStyles from '../../../../../styles/buttons';
import UploadStyles from './file-uploader-styles';
import InputStyles from './input-styles';

import { UploadFile } from '../../../../../actions/intakes';

// TODO: check that this will work with mobile browsers as expected
const FileUploader = ({
    classes,
    questionProps,
    manageFormInputs,
    intakeId,
}) => {
    const {
        id,
        userInput,
    } = questionProps;

    const [uploadStatus, setUploadStatus] = useState(null);
    const [returnedFileKey, setReturnedFileKey] = useState('');
    const [uploadErrorMsg, setUploadErrorMsg] = useState('');

    const dispatch = useDispatch();

    // success handler
    useEffect(() => {
        if (uploadStatus === 'success' && returnedFileKey !== '') {
            manageFormInputs({ id, userInput: returnedFileKey });
            setReturnedFileKey('');
        }
    }, [uploadStatus, returnedFileKey]);

    const SelectFile = () => {
        document.getElementById(`file-input-${id}`).click();
    };
    const SelectFileHandleChange = () => {
        const fileToUpload = document.getElementById(`file-input-${id}`).files[0];
        // only procceed if we have a file selected
        if (fileToUpload) {
            setUploadStatus('begin');
            if (fileToUpload.size > 10485760) {
                setUploadStatus('error');
                setUploadErrorMsg('This file exceeds the 10 MB limit.');
            } else {
                dispatch(UploadFile({ file: fileToUpload, id: intakeId }))
                    .then((returnValue) => {
                        if (returnValue.status === 200) {
                            setUploadStatus('success');
                            setReturnedFileKey(returnValue.data.fileKey);
                        }
                    })
                    .catch(() => {
                        setUploadStatus('error');
                        setUploadErrorMsg('Error uploading file.');
                    });
            }
        }
    };

    const clearSelectedFile = () => {
        setUploadStatus(null);
        manageFormInputs({ id, userInput: '' });
        setReturnedFileKey('');
        setUploadErrorMsg('');
    };

    return (
        <>
            <div className="row align-items-center">
                <div className="col-auto">
                    <input id={`file-input-${id}`} type="file" name="fileUpload" accept="image/*, .pdf" style={{ display: 'none' }} onChange={SelectFileHandleChange} />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={SelectFile}
                        className={classes.greenButton}
                        style={{ textTransform: 'uppercase', letterSpacing: 1, height: 40 }}
                        classes={{
                            label: classes.greenButtonLabel,
                        }}
                        TouchRippleProps={{
                            classes: {
                                childPulsate: classes.greenButtonRippleChildPulsate,
                            },
                        }}
                    >
                        {userInput === '' ? 'Choose File' : 'Change File'}
                    </Button>
                </div>
                <div className="col" style={{ paddingRight: 30 }}>
                    <div className="d-inline" style={{ whiteSpace: 'nowrap' }} aria-live="polite" role="status">
                        {uploadStatus === 'begin' && (
                            <div className={classes.meter}>
                                <span className={classes.meterLoading} />
                            </div>
                        )}
                        {(uploadStatus === 'success' || userInput !== '') && (
                            <>
                                <button type="button" onClick={clearSelectedFile} style={{ paddingRight: 10 }} aria-label="remove file">
                                    <RemoveCircleOutlineIcon className={classes.deletedBtnIcon} />
                                </button>
                                {userInput}
                                <div className={classes.meter}>
                                    <span className={classes.meterLoaded} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {uploadStatus === 'error' && <Typography variant="body2" className={classes.error}>{uploadErrorMsg}</Typography>}
        </>
    );
};

FileUploader.propTypes = {
    classes: PropTypes.object.isRequired,
    questionProps: PropTypes.shape({ // parent param, everything else is contained inside this
        id: PropTypes.number.isRequired,
        userInput: PropTypes.string.isRequired,
    }),
    // parent component determins validation errors
    manageFormInputs: PropTypes.func.isRequired,
    intakeId: PropTypes.string.isRequired,
};
FileUploader.defaultProps = {
    questionProps: {},
};

const combinedStyles = CombineStyles(InputStyles, ButtonStyles, UploadStyles);
export default withStyles(combinedStyles)(FileUploader);
